import {createAction} from "redux-act";
// filter 
export const requestChangeRightFilter = createAction('[transactions] requestChangeRightFilter');
// filter handler
export const useFilterSuccess =  createAction('[transactions] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[transactions] requestGetDataPage');
export const getDataPageSuccess = createAction('[transactions] getDataPageSuccess');
export const getDataPageError = createAction('[transactions] getDataPageError');
// update
export const requestUpdateData = createAction('[transactions] requestUpdateData');
export const updateDataSuccess = createAction('[transactions] updateDataSuccess');
export const updateDataError = createAction('[transactions] updateDataError'); 
// get companies data
export const requestCompaniesData = createAction('[unIdentifiedObjects] requestCompaniesData');
export const companiesDataSuccess = createAction('[unIdentifiedObjects] CompaniesDataSuccess');
export const companiesDataError = createAction('[unIdentifiedObjects] CompaniesDataError')
//clear fields
export const clearRightFilterFields = createAction('[transactions] clearRightFilterFields');
export const clearUpdateFields = createAction('[transactions] clearUpdateFields');

// pagination
export const changePagination = createAction('[transactions] changePagination');
//windows
export const showWindowUpdate = createAction('[transactions] showWindowUpdate') 

// filter
export const REQUEST_CHANGE_DRIVER_STATUS = 'REQUEST_CHANGE_DRIVER_STATUS'
export const REQUEST_CHANGE_DRIVER_SEARCH = 'REQUEST_CHANGE_DRIVER_SEARCH'
export const CHANGE_RIGHT_FILTER_DRIVERS = 'CHANGE_RIGHT_FILTER_DRIVERS' 
export const USE_FILTER_SUCCESS_DRIVERS = 'USE_FILTER_SUCCESS_DRIVERS' 
// get
export const REQUEST_GET_DRIVERS = 'REQUEST_GET_DRIVERS'
export const GET_DATA_DRIVERS_SUCCESS = 'GET_DATA_DRIVERS_SUCCESS' 
export const GET_DATA_DRIVERS_ERROR = 'GET_DATA_DRIVERS_ERROR' 
// delete 
export const REQUEST_DELETE_DRIVERS = 'REQUEST_DELETE_DRIVERS'
export const DELETE_DRIVERS_SUCCESS = 'DELETE_DRIVERS_SUCCESS' 
export const DELETE_DRIVERS_ERROR = 'DELETE_DRIVERS_ERROR' 
// update 
export const REQUEST_UPDATE_DRIVERS = 'REQUEST_UPDATE_DRIVERS'
export const UPDATE_DRIVERS_SUCCESS = 'UPDATE_DRIVERS_SUCCESS' 
export const UPDATE_DRIVERS_ERROR = 'UPDATE_DRIVERS_ERROR' 
// create 
export const REQUEST_CREATE_DRIVER = 'REQUEST_CREATE_DRIVER'
export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS' 
export const CREATE_DRIVER_ERROR = 'CREATE_DRIVER_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_DRIVER = 'SHOW_WINDOW_ADD_DRIVER' 
export const SHOW_WINDOW_UPDATE_DRIVER = 'SHOW_WINDOW_UPDATE_DRIVER'  
// get cars in window
export const REQUEST_GET_CARS_FOR_DRIVERS = 'REQUEST_GET_CARS_FOR_DRIVERS' 
export const GET_CARS_FOR_DRIVERS_SUCCESS = 'GET_CARS_FOR_DRIVERS_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_DRIVERS = 'REQUEST_GET_ONECAR_FOR_DRIVERS' 
export const GET_ONECAR_FOR_DRIVERS_SUCCESS = 'GET_ONECAR_FOR_DRIVERS_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_DRIVERS = 'CLEAR_CREATE_FIELDS_DRIVERS' 
export const CLEAR_UPDATE_FIELDS_DRIVERS = 'CLEAR_UPDATE_FIELDS_DRIVERS'  
export const CLEAR_DELETE_FIELDS_DRIVERS = 'CLEAR_DELETE_FIELDS_DRIVERS'  


// --- Window requests
// получаем аудит
export const REQUEST_GET_AUDIT_FOR_DRIVERS = 'REQUEST_GET_AUDIT_FOR_DRIVERS' 
export const SUCCESS_GET_AUDIT_FOR_DRIVERS = 'SUCCESS_GET_AUDIT_FOR_DRIVERS'  
// получаем аудит
export const REQUEST_GET_DRIVERORDERS_FOR_DRIVERS = 'REQUEST_GET_DRIVERORDERS_FOR_DRIVERS' 
export const SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS = 'SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS'   
// получаем фильтрованные заказы по водителю
export const REQUEST_GET_DRIVERORDERS_FOR_DRIVERS_PARAMS = 'REQUEST_GET_DRIVERORDERS_FOR_DRIVERS_PARAMS' 
export const SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS_PARAMS = 'SUCCESS_GET_DRIVERORDERS_FOR_DRIVERS_PARAMS'   
// получаем все события
export const REQUEST_GET_LOGEVENTS_FOR_DRIVERS = 'REQUEST_GET_LOGEVENTS_FOR_DRIVERS' 
export const SUCCESS_GET_LOGEVENTS_FOR_DRIVERS = 'SUCCESS_GET_LOGEVENTS_FOR_DRIVERS'    
// получаем финансы
export const REQUEST_GET_FINANCES_FOR_DRIVERS = 'REQUEST_GET_FINANCES_FOR_DRIVERS' 
export const SUCCESS_GET_FINANCES_FOR_DRIVERS = 'SUCCESS_GET_FINANCES_FOR_DRIVERS'    
// создаем в финансах пополнение водителю
export const REQUEST_CREATE_REPLENISH_FOR_DRIVERS = 'REQUEST_CREATE_REPLENISH_FOR_DRIVERS' 
export const SUCCESS_CREATE_REPLENISH_FOR_DRIVERS = 'SUCCESS_CREATE_REPLENISH_FOR_DRIVERS'     
export const ERROR_CREATE_REPLENISH_FOR_DRIVERS = 'ERROR_CREATE_REPLENISH_FOR_DRIVERS'     
export const CLEARFIELD_CREATE_REPLENISH_FOR_DRIVERS = 'CLEARFIELD_CREATE_REPLENISH_FOR_DRIVERS'     
// снятие денег в финансах
export const REQUEST_CREATE_WITHDRAW_FOR_DRIVERS = 'REQUEST_CREATE_WITHDRAW_FOR_DRIVERS' 
export const SUCCESS_CREATE_WITHDRAW_FOR_DRIVERS = 'SUCCESS_CREATE_WITHDRAW_FOR_DRIVERS'     
export const ERROR_CREATE_WITHDRAW_FOR_DRIVERS = 'ERROR_CREATE_WITHDRAW_FOR_DRIVERS'     
export const CLEARFIELD_CREATE_WITHDRAW_FOR_DRIVERS = 'CLEARFIELD_CREATE_WITHDRAW_FOR_DRIVERS'    
	// get photos for one driver
export const REQUEST_GET_DRIVERPHOTOS_DRIVERS = 'REQUEST_GET_DRIVERPHOTOS_DRIVERS' 
export const GET_DRIVERPHOTOS_DRIVERS_SUCCESS = 'GET_DRIVERPHOTOS_DRIVERS_SUCCESS'  
// получить все параметры-фильтры для аудита
export const REQUEST_GET_FILTERPARAMS_DRIVERS = 'REQUEST_GET_FILTERPARAMS_DRIVERS' 
export const GET_FILTERPARAMS_DRIVERS_SUCCESS = 'GET_FILTERPARAMS_DRIVERS_SUCCESS'   
// получаем данные 1 водителя (вызов из Adapter.js)
export const REQUEST_GET_OVEDRIVER_DRIVERS = 'REQUEST_GET_OVEDRIVER_DRIVERS'  
export const SUCCESS_GET_OVEDRIVER_DRIVERS = 'SUCCESS_GET_OVEDRIVER_DRIVERS'  
// меняем пагинацию 
export const CHANGE_PAGIN_DRIVERS = 'CHANGE_PAGIN_DRIVERS'   

// манипуляция координат в диалоговом с картой
export const CHANGE_MAP_COORD = 'CHANGE_MAP_COORD'
export const CLEAR_MAP_COORD = 'CLEAR_MAP_COORD'

// получае информацию о водителе для инфо карточки
export const REQUEST_GET_DRIVER_INFO = 'REQUEST_GET_DRIVER_INFO'  
export const SUCCESS_GET_DRIVER_INFO = 'SUCCESS_GET_DRIVER_INFO' 
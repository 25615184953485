import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/partners';  
import * as actions from './actions' 
 
export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),       
	takeLatest(actions.requestUpdateData, updateData),  

  takeLatest(actions.requestChangeStatus, useFilter),   
  takeLatest(actions.requestChangeParamSearch, useFilter),   
	takeLatest(actions.requestChangeRightFilter, useFilter),   
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {   
		yield put(actions.getDataPageError(error))
	}
}

// 
function* useFilter(action) { 
	try {   
		const data = yield call(API.getData, action.payload.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
} 
  
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR BLACKLIST) ', error); 
		yield put(actions.updateDataError(error))
	}
}
   
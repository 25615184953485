import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../../api/reports/reportCarsOnline";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestCreateData, createData),
  takeLatest(actions.requestDeleteData, deleteData),
  takeLatest(actions.requestUpdateData, updateData),
  takeLatest(actions.requestChangeStatus, useFilter),
  takeLatest(actions.requestChangeParamSearch, useFilter),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.requestGetCompaniesData, requestCompanies),
  takeLatest(actions.requestGetRegionsData, requestRegions)
];


function* requestCompanies(action){
  try{
    const data = yield call(API.getCompanies, action.payload);
    yield put(actions.getCompaniesDataSuccess(data));
  }catch(error){
    console.log("getCompanies ERROR, reportCarsOnline", error);
    yield put(actions.getCompaniesDataError(error));
  }
}

function* requestRegions(action){
  try{
    const data = yield call(API.getRegions, action.payload);
    yield put(actions.getRegionsDataSuccess(data));
  }catch(error){
    console.log("getRegions ERROR, reportCarsOnline", error);
    yield put(actions.getRegionsDataError(error));
  }
}


// 1
function* requestGetData(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    console.error("(getDataPageError reportCarsOnline)", error);
    yield put(actions.getDataPageError(error));
  }
}

//
function* useFilter(action) {
  try {
    const data = yield call(API.getData, action.payload.filterData);
    yield put(actions.useFilterSuccess(data));
  } catch (error) {
    yield put(actions.useFilterSuccess([]));
  }
}

//
function* createData(action) {
  try {
    const data = yield call(API.createData, action.payload);
    yield put(actions.createDataSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.createDataError(error));
  }
}

//
function* deleteData(action) {
  try {
    const data = yield call(API.deleteData, action.payload);
    yield put(actions.deleteDataSuccess(data));
  } catch (error) {
    console.error("(deleteData for reportCarsOnline) ", error);
    yield put(actions.deleteDataError(error));
  }
}

//
function* updateData(action) {
  try {
    const data = yield call(API.updateData, action.payload);
    yield put(actions.updateDataSuccess(data));
  } catch (error) {
    console.error("(updateData for reportCarsOnline) ", error);
    yield put(actions.updateDataError(error));
  }
}

import reportManager from './reportManager/reducer';
import reportGiveOrder from "./reportGiveOrder/reducer";
import reportRecieveOrder from "./reportRecieveOrder/reducer";
import reportAgent from "./reportAgent/reducer";  
import reportCarsOnline from "./reportCarsOnline/reducer";
import reportExecutedOrders from "./reportExecutedOrders/reducer";
import reportPhotoinspection from "./reportPhotoinspection/reducer";
import auditAirport from "./auditAirport/reducer";

import {reducer as reportTitle} from './reportTitle'; 

export default (state={}, action) => {  
	return {
		reportManager: reportManager(state.reportManager, action),
		reportGiveOrder: reportGiveOrder(state.reportGiveOrder, action),
		reportRecieveOrder: reportRecieveOrder(state.reportRecieveOrder, action),
		reportAgent: reportAgent(state.reportAgent, action),
		reportCarsOnline: reportCarsOnline(state.reportCarsOnline, action),
		reportExecutedOrders: reportExecutedOrders(state.reportExecutedOrders, action),
		reportTitle: reportTitle(state.reportTitle, action),
		reportPhotoinspection: reportPhotoinspection(state.reportPhotoinspection, action),
		auditAirport: auditAirport(state.auditAirport, action)
	} 
} 
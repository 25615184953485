import { createAction } from 'redux-act';


// filter 
export const requestChangeStatus = createAction('[clientDebtsList] requestChangeStatus')
export const requestChangeParamSearch = createAction('[clientDebtsList] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[clientDebtsList] requestChangeRightFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[clientDebtsList] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[clientDebtsList] requestGetDataPage')
export const getDataPageSuccess = createAction('[clientDebtsList] getDataPageSuccess')
export const getDataPageError = createAction('[clientDebtsList] getDataPageError')

// delete
export const requestDeleteData = createAction('[clientDebtsList] requestDeleteData')
export const deleteDataSuccess = createAction('[clientDebtsList] deleteDataSuccess')
export const deleteDataError = createAction('[clientDebtsList] deleteDataError')
// create
export const requestCreateData = createAction('[clientDebtsList] requestCreateData')
export const createDataSuccess = createAction('[clientDebtsList] createDataSuccess')
export const createDataError = createAction('[clientDebtsList] createDataError') 


// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = createAction('[clientDebtsList] clearCreateFields') 
export const clearUpdateFields = createAction('[clientDebtsList] clearUpdateFields') 
export const clearDeleteFields = createAction('[clientDebtsList] clearDeleteFields')  


export const clearCreateDeleteStates = createAction('[clientDebtsList] clearCreateDeleteStates')
export const createInProgress = createAction('[clientDebtsList] createInProgress')
export const deleteInProgress = createAction('[clientDebtsList] deleteInProgress')

// pagination
export const changePagination = createAction('[clientDebtsList] changePagination')

// small part for canceled statistic
export const requestChangeFilterCancStatistics = createAction('[cancelReason] requestChangeFilterCancStatistics')

export const requestGetDataCancStatistics = createAction('[cancelReason] requestGetDataCancStatistics')
export const successGetDataCancStatistics = createAction('[cancelReason] successGetDataCancStatistics')

export const setLoadingIndicator = createAction('[cancelReason] setLoadingIndicator')


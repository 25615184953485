 
// update 
export const REQUEST_UPDATE_SYSTEMSETTINGS = 'REQUEST_UPDATE_SYSTEMSETTINGS'
export const UPDATE_SYSTEMSETTINGS_SUCCESS = 'UPDATE_SYSTEMSETTINGS_SUCCESS' 
export const UPDATE_SYSTEMSETTINGS_ERROR = 'UPDATE_SYSTEMSETTINGS_ERROR'  
export const CLEAR_UPDATE_FIELDS_SYSTEMSETTINGS = 'CLEAR_UPDATE_FIELDS_SYSTEMSETTINGS'    


// --- Windows request  
export const REQUEST_GET_SYSTEMSETTINGS = 'REQUEST_GET_SYSTEMSETTINGS'
export const SUCCESS_GET_SYSTEMSETTINGS = 'SUCCESS_GET_SYSTEMSETTINGS' 
 
export const LOAD_SMS_SERVICES = 'LOAD_SMS_SERVICES'
 
 
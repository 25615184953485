import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/controlRoom/corpClients';
import API_DRIVER from '../../../../api/dictionaries/drivers';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_CORPCLIENTS, requestGetData),   
	takeLatest(types.REQUEST_CREATE_CORPCLIENTS, createData),    
	takeLatest(types.REQUEST_DELETE_CORPCLIENTS, deleteData),    
	takeLatest(types.REQUEST_UPDATE_CORPCLIENTS, updateData),  

  takeLatest(types.REQUEST_CHANGE_CORPCLIENTS_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_CORPCLIENTS_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_CORPCLIENTS, useFilter),  
	// passangers
	takeLatest(types.REQUEST_GET_PASSENGERS_FOR_CORPCLIENTS, getPassengers), 
	takeLatest(types.REQUEST_CREATE_PASSENGERS_CORPCLIENTS, createPassengers), 
	takeLatest(types.REQUEST_DEL_PASSENGERS_CORPCLIENTS, delPassengers), 
	takeLatest(types.REQUEST_UPDATE_PASSENGERS_CORPCLIENTS, updatePassengers), 
	takeLatest(types.REQUEST_GET_AUDIT_FOR_PASSENGERS_CORPCLIENTS, getAuditPassengers), 

	takeLatest(types.REQUEST_GET_AUDIT_FOR_CORPCLIENTS, getAudit),  
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError CORPCLIENTS)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
  
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData CORPCLIENTS) ', error); 
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR CORPCLIENTS) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR CORPCLIENTS) ', error); 
		yield put(actions.updateDataError(error))
	}
}
  
//  
function* getAudit(action) {
	try {   
		const data = yield call(API.getAudit, action.payload);   
		yield put(actions.getGetAuditSuccess(data))   
		
	} catch (error) {  
		console.error('(getGetAuditSuccess FOR CORPCLIENTS) ', error); 
		yield put(actions.getGetAuditSuccess([]))
	}
}

//  --- Passengers ---
function* getPassengers(action) {
	try {   
		const data = yield call(API.getPassengers, action.payload);   
		yield put(actions.getPassengersSuccess(data))   
		
	} catch (error) {  
		console.error('(getPassengers FOR CORPCLIENTS) ', error); 
		yield put(actions.getPassengersSuccess([]))
	}
}

// 
function* delPassengers(action) {
	try {   
		const data = yield call(API.delPassengers, action.payload);  
		yield put(actions.successDeletePassengers(data))   
		
	} catch (error) {  
		console.error('(delPassengers FOR CORPCLIENTS) ', error); 
		yield put(actions.errorDeletePassengers(error))
	}
}
 
// 
function* createPassengers(action) {
	try {   
		const data = yield call(API.addPassengers, action.payload);  
		yield put(actions.successCreatePassengers(data))   
		
	} catch (error) {  
		console.error('(createPassengers FOR CORPCLIENTS) ', error); 
		yield put(actions.errorCreatePassengers(error))
	}
}
 
// 
function* updatePassengers(action) {
	try {   
		const data = yield call(API.updatePassengers, action.payload);  
		yield put(actions.successUpdatePassengers(data))   
		
	} catch (error) {  
		console.error('(updatePassengers FOR CORPCLIENTS) ', error); 
		yield put(actions.errorUpdatePassengers(error))
	}
}
 
// 
function* getAuditPassengers(action) {
	try {   
		const data = yield call(API.getAuditPassengers, action.payload);  
		yield put(actions.successAuditPassengers(data))   
		
	} catch (error) {  
		console.error('(getAuditPassengers FOR CORPCLIENTS) ', error); 
		yield put(actions.successAuditPassengers([]))
	}
}
import * as actions from "./actions";
import { createReducer } from "redux-act";
import { filterDataForId } from "../../../../core/utils/filters/MainFilters";
import dict from "../../../../core/dict/controlRoom/cancelReason";

const initialState = {
  filter: {
    paramSearch: dict.params[0].value,
    valueSearch: "",
    debtReason: null,
    rightFilter: {},
    page: 1,
    countPage: 100
  },
  data: [],
  loading: false,
  create: {
    // для работы при создании
    loading: false,
    error: false,
    success: false
  },
  delete: {
    // при удалении
    loading: false,
    error: false,
    success: false
  },
  
  // part for small canceled statistic
  filterCancelStatistics: {
    paramSearch: dict.params[0].value,
    valueSearch: "",
    reason: null,
    page: 1,
    countPage: 100
  },
  loadingStatistics: false,
  dataCancelStatisctis: []

};


export default createReducer(
  {

    [actions.requestChangeParamSearch]: (state, action) => ({
      ...state,
      loading: true,
      filter: {
        ...state.filter,
        paramSearch: action.redux.paramSearch,
        valueSearch: action.redux.valueSearch
      }
    }),

    [actions.requestChangeRightFilter]: (state, action) => ({
      ...state,
      loading: true,
      filter: { ...state.filter, rightFilter: action }
    }),

    [actions.useFilterSuccess]: (state, action) => ({
      ...state,
      loading: false,
      data: filterDataForId(action)
    }),

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({ ...state, loading: true }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: filterDataForId(action) };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),

    [actions.createDataSuccess]: (state, action) => ({...state, create: {success: true, error: false, loading: false}}),
    [actions.createDataError]: (state, action) => ({...state, create: {success: false, error: true, loading: false}}),

    [actions.deleteDataSuccess]: (state, action) => ({...state, delete: {success: true, error: false, loading: false}}),
    [actions.deleteDataError]: (state, action) => ({...state, delete: {success: false, error: true, loading: false}}),

    [actions.clearCreateDeleteStates]: (state)=>({
      ...state,
      delete: {success: false, error: false, loading: false},
      create: {success: false, error: false, loading: false}
    }),

    [actions.createInProgress]: (state, value)=>({...state, create: {...state.create, loading: value}}),
    [actions.deleteInProgress]: (state, value)=>({...state, delete: {...state.delete, loading: value}}),

    [actions.successGetDataCancStatistics]: (state, data) =>({...state, dataCancelStatisctis: data}),
    [actions.requestChangeFilterCancStatistics]: (state, data) =>{ return {...state, filterCancelStatistics: data};},
    [actions.setLoadingIndicator]: (state, data) => ({...state, loadingStatistics: data}),
    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);

import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/reports/reports';  
import * as actions from './actions' 
 
export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),    
	
  takeLatest(actions.requestChangeStatus, useFilter),   
  takeLatest(actions.requestChangeParamSearch, useFilter),   
	takeLatest(actions.requestChangeRightFilter, useFilter),   
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.reportManager, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {   
		yield put(actions.getDataPageError(error))
	}
}

// 
function* useFilter(action) {
	try {   
		const data = yield call(API.reportManager, action.payload);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
  
  
export default {    
  status: [ 
    {'value': '0', 'label': 'Все компании' },
    {'value': '1', 'label': 'Своя компания'}, 
  ], 
  params: [ 
    {'label': 'Телефон', 'value': 'phone'}, 
    {'label': 'Имя', 'value': 'name'},   
    {'label': 'Фамилия', 'value': 'surname'},   
    {'label': 'Номер заказа', 'value': 'orderId'},         
  ],  
  reasons: [ 
    {'label': 'Заказал по ошибке', 'value': 'ORDERED_BY_MISTAKE'}, 
    {'label': 'Слишком долго ждать', 'value': 'TOO_LONG_TO_WAIT'},   
    {'label': 'Передумал', 'value': 'CHANGED_MIND'},   
    {'label': 'Ошибся адресом', 'value': 'WRONG_ADDRESS'},
    {'label': 'Водитель попросил отменить заказ', 'value': 'DRIVER_ASKED_TO_CANCEL'},
    {'label': 'Другое', 'value': 'OTHER'},   
  ],
  reasonDebt: [
    {'label': 'Отмена клиентом когда водитель уже приехал', 'value': 'CANCELLED_WITH_DRIVER'},
    {'label': 'Отмена водителем после долгого ожидания', 'value': 'CLIENT_DIDNT_COME_OUT'},
    {'label': 'Ручное выставление оператором', 'value': 'UNPAID_FINISHED_ORDER'},
    {'label': 'Любые', 'value': null},
  ]  
}

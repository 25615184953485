export const CHANGE_CAR_STATUS = 'CHANGE_CAR_STATUS'
export const CHANGE_CAR_SEARCH = 'CHANGE_CAR_SEARCH'
export const CHANGE_RIGHT_FILTER_CARS = 'CHANGE_RIGHT_FILTER_CARS'

export const SHOW_WINDOW_ADD_CAR = 'SHOW_WINDOW_ADD_CAR'
export const SHOW_WINDOW_UPDATE_CAR = 'SHOW_WINDOW_UPDATE_CAR'
// get data
export const REQUEST_GET_CARS = 'REQUEST_GET_CARS'
export const REQUEST_GET_CARS_SUCCESS = 'REQUEST_GET_CARS_SUCCESS' 
export const REQUEST_GET_CARS_ERROR = 'REQUEST_GET_CARS_ERROR'   
// create
export const REQUEST_CREATE_CAR = 'REQUEST_CREATE_CAR'
export const CREATE_CAR_SUCCESS = 'CREATE_CAR_SUCCESS' 
export const CREATE_CAR_ERROR = 'CREATE_CAR_ERROR' 
export const CLEAR_CREATE_FIELDS = 'CLEAR_CREATE_FIELDS' 
// update
export const REQUEST_UPDATE_CAR = 'REQUEST_UPDATE_CAR'
export const UPDATE_CAR_SUCCESS = 'UPDATE_CAR_SUCCESS' 
export const UPDATE_CAR_ERROR = 'UPDATE_CAR_ERROR' 
export const CLEAR_UPDATE_FIELDS = 'CLEAR_UPDATE_FIELDS' 
// delete
export const REQUEST_DELETE_CAR = 'REQUEST_DELETE_CAR'
export const DELETE_CAR_SUCCESS = 'DELETE_CAR_SUCCESS' 
export const DELETE_CAR_ERROR = 'DELETE_CAR_ERROR'  
export const CLEAR_DELETE_FIELDS = 'CLEAR_DELETE_FIELDS'  
// get drivers
export const REQUEST_GET_DRIVERS_CARS = 'REQUEST_GET_DRIVERS_CARS'
export const GET_DRIVERS_CARS_SUCCESS = 'GET_DRIVERS_CARS_SUCCESS'
// get attachedDrivers
export const REQUEST_GET_ATTACHED_DRIVERS_CARS = 'REQUEST_GET_ATTACHED_DRIVERS_CARS'
export const GET_ATTACHED_DRIVERS_CARS_SUCCESS = 'GET_ATTACHED_DRIVERS_CARS_SUCCESS'

export const CARS_FILTER_SUCCESS = 'CARS_FILTER_SUCCESS' 
// получаем данные 1 машину (вызов из Adapter.js)
export const REQUEST_GET_ONECAR_CARS = 'REQUEST_GET_ONECAR_CARS'
export const SUCCESS_GET_ONECAR_CARS = 'SUCCESS_GET_ONECAR_CARS'
// меняем пагинацию 
export const CHANGE_PAGIN_CARS = 'CHANGE_PAGIN_CARS' 
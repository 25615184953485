import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters';

const initialState = { 
	filter: { 
		paramSearch: 'name', // поиск по колонке  
		valueSearch: '', // поиск по колонке - текстовое поле
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  // список машин
	loaded: false, // при загрузки машин
	create: { // для работы при создании 
		loaded: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loaded: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loaded: false,  
		error: false,   
		success: false,   
	},
	windowCreateOpened: false, 
	windowUpdateOpened: false,  
	cars: [], // список машин  
}

export default (state=initialState, action) => {
 
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_COLUMNS_STATUS:   
			return {...state, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_COLUMNS_SEARCH: 
			return {...state, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_COLUMNS:  
			return {...state, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_COLUMNS:   
			return {...state, loaded: true, data: filterDataForId(action.payload)} 
 

		// --- Async get ->>
		case types.REQUEST_GET_COLUMNS:  
			return {...state, loaded: false}

		case types.GET_DATA_COLUMNS_SUCCESS:     
			return {...state, loaded: true, data: filterDataForId(action.payload)} 

		case types.GET_DATA_COLUMNS_ERROR: 
			return {...state, loaded: true, data: [], error: action.payload} 


	// --- Async delete data ->>
	case types.REQUEST_DELETE_COLUMNS: 
		return {...state, delete: {...state.delete, loaded: true}}  
	case types.DELETE_COLUMNS_SUCCESS:  
		newData = state.data.filter(el=> el.id !== action.payload) 
		return {...state, delete: {...state.delete, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
	case types.DELETE_COLUMNS_ERROR: 
		return {...state, delete: {...state.delete, loaded: false, error: action.error, success: false}}  
	case types.CLEAR_DELETE_FIELDS_COLUMNS: 
		return {...state, delete: {...state.delete, loaded: false, error: false, success: false}} // initial 


		// --- Async update ->>
	case types.REQUEST_UPDATE_COLUMNS: 
		return {...state, update: {...state.update, loaded: true}}  
	case types.UPDATE_COLUMNS_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loaded: false, success: true, error: false}, data: newData}  
	case types.UPDATE_COLUMNS_ERROR: 
		return {...state, update: {...state.update, loaded: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_COLUMNS: 
		return {...state, update: {...state.update, loaded: false, error: false, success: false}} // initial 


		// --- Async create ->>
		case types.REQUEST_CREATE_COLUMNS: 
			return {...state, create: {...state.create, loaded: true}}  
		case types.CREATE_COLUMNS_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_COLUMNS_ERROR: 
			return {...state, create: {...state.create, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_COLUMNS: 
			return {...state, create: {...state.create, loaded: false, error: false, success: false}} // initial 
 

		// показ окон
		case types.SHOW_WINDOW_ADD_COLUMNS:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_COLUMNS:    
			return {...state, windowUpdateOpened: action.payload} 

		// --- Async get cars in window ->>
		case types.REQUEST_GET_CARS_FOR_COLUMNS: 
			return {...state, cars: []}

		case types.GET_CARS_FOR_COLUMNS_SUCCESS:   
			return {...state, cars: action.payload} 
 
	
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			
		default: 
			return state
	}
}
 
import blacklist from './blacklistPage/saga'; 
import corpClients from './corpClients/saga'; 
import ordersPage from './ordersPage/saga'; 
import map from './map/saga'; 
import clients from './clients/saga'; 
import driverBlacklistPage from './driverBlacklistPage/saga'; 
import debt from './clientDebtsPage/saga'
 
export default [ 
  ...debt,
  ...blacklist, 
  ...corpClients, 
  ...ordersPage, 
  ...map, 
  ...clients, 
  ...driverBlacklistPage, 
]
 
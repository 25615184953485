import { createAction } from 'redux-act';


export const requestGetDataPage = createAction('[reportPhotoinspection] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportPhotoinspection] getDataPageSuccess')
export const changeAnyFilter = createAction('[reportPhotoinspection] changeAnyFilter')

export const requestGetCompaniesData = createAction('[reportPhotoinspection] requestGetCompaniesData')
export const getCompaniesDataSuccess = createAction('[reportPhotoinspection] getCompaniesDataSuccess')

export const requestGetUsers = createAction('[reportPhotoinspection] requestGetUsers')
export const getUsersDataSuccess = createAction('[reportPhotoinspection] getUsersDataSuccess')

export const getDataPageError = createAction('[reportPhotoinspection] getDataPageError')
 
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/dictionaries/columns';
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_COLUMNS, requestGetData),    
	takeLatest(types.REQUEST_CREATE_COLUMNS, createData),    
	takeLatest(types.REQUEST_UPDATE_COLUMNS, updateData),    
	takeLatest(types.REQUEST_DELETE_COLUMNS, deleteData),    

	takeLatest(types.REQUEST_CHANGE_COLUMNS_SEARCH, useFilter),    
]

// 1
function* requestGetData(action) {
	try {    
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError COLUMNS)', error);
		yield put(actions.getDataPageError(error))
	}
}
 
// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
// 
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData COLUMNS) ', error); 
		yield put(actions.createDataError([]))
	}
}

// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR COLUMNS) ', error); 
		yield put(actions.updateDataError(error))
	}
}

// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR COLUMNS) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
import сreateCompany  from './CreateCompanyPage/reducer';  
import createUserPage  from './CreateUserPage/reducer';  
import settingsPage  from './SettingsPage/reducer';  
import rolesPage  from './RolesPage/reducer';  
import tariffEdit  from './TariffEditPage/reducer';  
import regionsPage  from './RegionsPage/reducer';  
import partners  from './PartnersPage/reducer';  
import parkServicesPage  from './ParkServicesPage/reducer';
import settingSystemPage  from './SettingsSystemPage/reducer';

export default (state={}, action) => {  
	return {
		сreateCompany: сreateCompany(state.сreateCompany, action),    
		createUserPage: createUserPage(state.createUserPage, action),    
		settingsPage: settingsPage(state.settingsPage, action),    
		rolesPage: rolesPage(state.rolesPage, action),    
		tariffEdit: tariffEdit(state.tariffEdit, action),    
		regionsPage: regionsPage(state.regionsPage, action),    
		partners: partners(state.partners, action),    
		parkServicesPage: parkServicesPage(state.parkServicesPage, action),
		settingSystemPage: settingSystemPage(state.settingSystemPage, action) 
	} 
} 
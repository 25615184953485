import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters';

const initialState = { 
	// храним данные что бы по хождению по роутам-путям. прежний стейт сохранялся
	filter: {
		status: 'any', //статус машины (работает...)
		paramSearch: 'numberPlate', // поиск по колонке (колонка - слово)
		valueSearch: '', // поиск по колонке - текстовое поле
		companyId: '',
		region: '',
		rightFilter: { //должны быть одинаковые свойства с  RightFilter.js  
			gearbox: 'any',   
			autoClassId: 'any',   
			childSeats: 0, 
			booster: 0, 
			skiing: 0, 
			universal: 0, 
			animals: 0, 
			wifi: 0, 
			licenseActive: null,

		}, 
		page: 1,
		countPage: 100, 
	}, 
	data: [],  // список машин
	allEntries: null,
	loaded: false, // при загрузки машин
	create: { // для работы при создании 
		loaded: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loaded: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loaded: false,  
		error: false,   
		success: false,   
	},
	windowCreateOpened: false, 
	windowUpdateOpened: false,  
	drivers: [], // список водителей для окон (можно прикрепить к авто)
	oneCar: {}, // одна машина вызов из Adapter.js
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		case types.CHANGE_CAR_STATUS:   
			return {...state, filter: {...state.filter, status: action.payload}}

		case types.CHANGE_CAR_SEARCH: 
			return {...state, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}} 

		case types.CHANGE_PAGIN_CARS:  
			return {...state, filter: {...state.filter, ...action.payload}}

		case types.CHANGE_RIGHT_FILTER_CARS:  
			return {...state, filter: {...state.filter, rightFilter: action.payload}}

		// показ окон
		case types.SHOW_WINDOW_ADD_CAR:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_CAR:    
			return {...state, windowUpdateOpened: action.payload} 

		// --- Async get data ->>
		case types.REQUEST_GET_CARS: 
			return {...state, loaded: false}

		case types.REQUEST_GET_CARS_SUCCESS:  
			return {...state, loaded: true, data: filterDataForId(action.payload.list), allEntries: action.payload.totalSize} 

		case types.REQUEST_GET_CARS_ERROR: 
			return {...state, loaded: true, data: [], error: action.payload} 


		// --- Async create data ->>
		case types.REQUEST_CREATE_CAR: 
			return {...state, create: {...state.create, loaded: true}}  
		case types.CREATE_CAR_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_CAR_ERROR: 
			return {...state, create: {...state.create, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS: 
			return {...state, create: {...state.create, loaded: false, error: false, success: false}} // initial 


		// --- Async update data ->>
		case types.REQUEST_UPDATE_CAR: 
			return {...state, update: {...state.update, loaded: true}}  
		case types.UPDATE_CAR_SUCCESS: 
				newData = state.data.map(el => { 
				if(el.id === action.payload.id) el = action.payload
				return el
			})
			return {...state, update: {...state.update, loaded: false, success: true, error: false}, data: newData}  
		case types.UPDATE_CAR_ERROR: 
			return {...state, update: {...state.update, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_UPDATE_FIELDS: 
			return {...state, update: {...state.update, loaded: false, error: false, success: false}} // initial 


		// --- Async delete data ->>
		case types.REQUEST_DELETE_CAR: 
			return {...state, delete: {...state.delete, loaded: true}}  
		case types.DELETE_CAR_SUCCESS:  
			newData = state.data.filter(el=> el.id !== action.payload) 
			return {...state, delete: {...state.delete, loaded: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_CAR_ERROR: 
			return {...state, delete: {...state.delete, loaded: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS: 
			return {...state, delete: {...state.delete, loaded: false, error: false, success: false}} // initial 


		// --- Async use filter ->>
		case types.CARS_FILTER_SUCCESS:  
			return {...state, loaded: true, data: filterDataForId(action.payload.list), allEntries: action.payload.totalSize} 

		// --- get drivers for cars ->>
		case types.GET_DRIVERS_CARS_SUCCESS: 
	
			return {...state, drivers: action.payload} 

		// --- get attachedDrivers for cars (уже прикрепленные 1 тачке) ->>
		case types.GET_ATTACHED_DRIVERS_CARS_SUCCESS: 
			return {...state, attachedDrivers: action.payload} 

		// получаем данные 1 машину (вызов из Adapter.js)
		case types.REQUEST_GET_ONECAR_CARS:  
			return {...state, oneCar: {}} 
		case types.SUCCESS_GET_ONECAR_CARS:  
			return {...state, oneCar: action.payload} 
	
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			
		default: 
			return state
	}
}
 
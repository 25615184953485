import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_ORDERS_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_ORDERS_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_ORDERS, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_ORDERS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_ORDERS, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_ORDERS_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_ORDERS_ERROR, error: payload})
// get one order
export const requestOneOrder = (payload) => ({type: types.REQUEST_ONE_ORDER, payload: payload});
export const getOneOrderSuccess = (payload) => ({type: types.GET_ONE_ORDER_SUCCESS, payload: payload});
export const getOneOrderError = (payload) => ({type: types.GET_ONE_ORDER_ERROR, payload: payload});
export const clearOneOrder = ()=>({type: types.CLEAR_ONE_ORDER});
//get companies
export const requestGetDataCompanies = (payload) => ({type: types.REQUEST_GET_DATA_COMPANIES, payload});
export const getDataCompaniesSuccess = (payload) => ({type: types.GET_DATA_COMPANIES_SUCCESS, payload});
export const getDataCompaniesError = (payload) => ({type: types.GET_DATA_COMPANIES_ERROR, payload});
//send order to the exchanger
export const sendOrder = (payload) => ({type: types.SEND_ORDER, payload});
export const sendOrderSuccess = (payload) => ({type: types.SEND_ORDER_SUCCESS, payload: payload});
export const sendOrderError = (payload) => ({type: types.SEND_ORDER_ERROR, error: payload});

//send companies
export const sendCompaniesData = (payload) => ({type: types.SEND_COMPANIES_DATA, payload});
export const sendCompaniesDataSuccess = (payload) => ({type: types.SEND_COMPANIES_DATA_SUCCESS, payload});
export const sendCompaniesDataError = (payload) => ({type: types.SEND_COMPANIES_DATA_ERROR, payload});
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_ORDERS, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_ORDERS_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_ORDERS_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_ORDERS, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_ORDERS_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_ORDERS_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_ORDERS, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_ORDERS_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_ORDERS_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_ORDERS, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_ORDERS, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_ORDERS}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_ORDERS}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_ORDERS})
export const clearCompaniesDataFields = () => ({type: types.CLEAR_COMPANIES_DATA_FIELDS});  
// получаем авто по аяксу в новом окне
export const requestGetCarsForORDERS = (payload) => ({type: types.REQUEST_GET_CARS_FOR_ORDERS, payload}) 
export const getCarsForORDERSuccess = (payload) => ({type: types.GET_CARS_FOR_ORDERS_SUCCESS, payload})  
// получаем 1 авто
export const requestGetOneCar = (payload) => ({type: types.REQUEST_GET_ONECAR_FOR_ORDERS, payload}) 
export const getOneCarSuccess = (payload) => ({type: types.GET_ONECAR_FOR_ORDERS_SUCCESS, payload})  
// изменяем любой дополнительный фильтр(не сайд-эффект)    payload={key: value} 
export const changeAnyFilter = (payload) => ({type: types.CHANGE_ANY_FILTER_ORDERS, payload})  
//  получаем 1 водителя
export const requestGetOneDriver = (payload) => ({type: types.REQUEST_GET_DRIVER_FOR_ORDERS, payload})  
export const getOneDriverSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVER_FOR_ORDERS, payload})

// --- Windows request
//  получаем контрагента counterpartyName 
export const requestGetCounterpartys = (payload) => ({type: types.REQUEST_GET_COUNTERPARTY_FOR_ORDERS, payload})  
export const getCounterpartysSuccess = (payload) => ({type: types.SUCCESS_GET_COUNTERPARTY_FOR_ORDERS, payload})  
//  получаем персон у контрагента 
export const requestGetPersons = (payload) => ({type: types.REQUEST_GET_PERSON_FOR_ORDERS, payload})  
export const getPersonsSuccess = (payload) => ({type: types.SUCCESS_GET_PERSON_FOR_ORDERS, payload}) 
//  получаем пассажира
export const requestGetPassenger = (payload) => ({type: types.REQUEST_GET_PASSENGER_FOR_ORDERS, payload})  
export const getPassengerSuccess = (payload) => ({type: types.SUCCESS_GET_PASSENGER_FOR_ORDERS, payload}) 
//  получаем кординаты payload = [запрос, имя поля в редакс]
export const requestGetTextAndCoordinates = (payload) => ({type: types.REQUEST_GET_COORDINATES_FOR_ORDERS, payload})  
export const successGetTextAndCoordinates = (payload) => ({type: types.SUCCESS_GET_COORDINATES_FOR_ORDERS, payload}) 
//  получаем водителей
export const requestGetDrivers = (payload) => ({type: types.REQUEST_GET_DRIVERS_FOR_ORDERS, payload})  
export const getGetDriversSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVERS_FOR_ORDERS, payload})
//  получаем сумму
export const requestGetSum = (payload) => ({type: types.REQUEST_GET_SUM_FOR_ORDERS, payload})  
export const getGetSumSuccess = (payload) => ({type: types.SUCCESS_GET_SUM_FOR_ORDERS, payload})
//  поулчаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_ORDERS, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_ORDERS, payload}) 

// получаем маршрут для трэка
export const requestGetMapTrack = (payload) => ({type: types.REQUEST_GET_MAPTRACK_FOR_ORDERS, payload})  
export const getMapTrackSuccess = (payload) => ({type: types.SUCCESS_GET_MAPTRACK_FOR_ORDERS, payload}) 
export const clearGetMapTrack = (payload) => ({type: types.CLEAR_FIELDS_MAPTRACK_FOR_ORDERS, payload}) 

// получаем таксометр для вкладки
export const requestGetTaximeter = (payload) => ({type: types.REQUEST_GET_TAXIMETER_FOR_ORDERS, payload})  
export const getTaximeterSuccess = (payload) => ({type: types.SUCCESS_GET_TAXIMETER_FOR_ORDERS, payload})  
export const clearTaximeter = () => ({type: types.CLEAR_TAXIMETER})  

// меняем пагинацию 
export const changePagination = payload => ({type: types.CHANGE_PAGIN_ORDERS, payload})

//
export const requestGetDriverTrack = (payload) => ({type: types.REQUEST_GET_DRIVERTRACK_FOR_ORDERS, payload})  
export const getDriverTrackSuccess = (payload) => ({type: types.SUCCESS_GET_DRIVERTRACK_FOR_ORDERS, payload}) 
export default {    
  status: [
    {'value': 'any', 'label': 'Все' },
    {'value': '0', 'label': 'Новый' },
    {'value': '1', 'label': 'Бронь'},
    {'value': '2', 'label': 'Выехал'},
    {'value': '3', 'label': 'Ожидает'},
    {'value': '4', 'label': 'В пути'},
    {'value': '5', 'label': 'Выполнен'},
    {'value': '6', 'label': 'Отменил клиент'},
    {'value': '7', 'label': 'Отменил водитель'}, 
    {'value': '10', 'label': 'Не был найден водитель'}, 
    {'value': '20', 'label': 'В работе'}, 
  ], 
  params: [
    {'label': 'Номер заказа', 'value': 'coreId'}, 
    {'label': 'Внешний номер заказа', 'value': 'foreignSoftId'},   
    {'label': 'Телефон клиента', 'value': 'number'},   
    {'label': 'Борт', 'value': 'driverNickname'},  
    {'label': 'Контрагент', 'value': 'counterpartyName'},    
    {'label': 'ФИО водителя', 'value': 'driverName'},    
    {'label': 'Адрес подачи', 'value': 'shortAddress'},        
  ], 
  payment: [ 
    {'label': 'Оплата', 'value': 'any'}, 
    {'label': 'Наличные', 'value': '0'},   
    {'label': 'Карта', 'value': '1'},   
    {'label': 'Безнал', 'value': '2'},    
    {'label': 'Промокод', 'value': '3'},        
  ],  
  from: [ 
    {'label': 'Свои', 'value': '0'},   
    {'label': 'Партнерские', 'value': '1'},  
    {'label': 'Все', 'value': '2'},          
  ],  
  paymentTypes: [
    {'label': 'Наличные', 'value': '0'},
    {'label': 'Безнал', 'value': '2'},
  ],
  projects: [
    {'label': 'Внуково', 'value': '0', 'data': {"vnukovoProject" : "1"}},

  ]
}
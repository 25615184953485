import * as actions from "./actions";
import { createReducer } from "redux-act";
import { filterDataForId } from "../../../../core/utils/filters/MainFilters";

const initialState = {
  filter: {
    startDate: new Date(),
    endDate: new Date(),
    companyId: ""
  },

  data: [],
  loading: false,
  error: null,
  companiesData: [],
  loadingCompanies: false,
  users: []

};

export default createReducer(
  {
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true,
      filter: { ...state.filter, ...action }
    }),
    [actions.getDataPageSuccess]: (state, data) => ({
      ...state,
      loading: false,
      data
    }),
    [actions.getCompaniesDataSuccess]: (state, companiesData)=>({...state, companiesData}),
    [actions.getUsersDataSuccess]: (state, users)=>({...state, users}),
    [actions.changeAnyFilter]: (state, payload)=>({
      ...state, filter: {...state.filter, ...payload}
    }),
    [actions.getDataPageError]: (state, payload)=>({
      ...state, loading: false,
    }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);

export default {    
  status: [
    {'value': 'any', 'label': 'Статус' },
    {'value': '0', 'label': 'Работает' }, 
    {'value': '1', 'label': 'Расторгли договор' }, 
    {'value': '2', 'label': 'Временно не работает' }, 
    {'value': '3', 'label': 'Черный список' },  
  ], 
  params: [ 
    {'label': 'Название компании', 'value': 'name'}, 
    {'label': 'Название договора', 'value': 'contractName'},   
    {'label': 'Телефон', 'value': 'phone'},          
  ],  
  passengersParams: [ 
    {'label': 'Номер заказа', 'value': 'contractName'}, 
    {'label': 'Номер телефона', 'value': 'phone'},   
    {'label': 'ФИО', 'value': 'fio'},          
  ],  
  who: [ 
    {'label': 'Все', 'value': 'any'}, 
    {'label': 'Персоны', 'value': '0'},   
    {'label': 'Пассажиры', 'value': '1'},          
  ],  
}
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/creatingCompany';  
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_CREATECOMP, requestGetData),   
	takeLatest(types.REQUEST_CREATE_CREATECOMP, createData),    
	takeLatest(types.REQUEST_DELETE_CREATECOMP, deleteData),    
	takeLatest(types.REQUEST_UPDATE_CREATECOMP, updateData),  
	takeLatest(types.REQUEST_GET_SERVICES_FOR_CREATECOMP, requestGetServices),  
	takeLatest(types.REQUEST_GET_COMPANY_FOR_CREATECOMP, requestGetOneCompany),  
	takeLatest(types.REQUEST_CHANGE_COMPANYORDERS_CREATECOMP, requestChangeCompanyOrders), 
	takeLatest(types.REQUEST_SMS_SERVICES_DATA, SMSData), 
	// takeLatest(types.REQUEST_GET_SUBCOMPANIES, getSubcompanies),
  takeLatest(types.REQUEST_CHANGE_CREATECOMP_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_CREATECOMP_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_CREATECOMP, useFilter),
	takeLatest(types.REQUEST_CHANGE_CREATECOMP_INWORK, useFilter),
	takeLatest(types.SEND_CORRECT_DATA, sendCorrectData),
	takeLatest(types.SEND_COMPANY_ID, sendCompanyId),
	takeLatest(types.REQUEST_RECOVER_COMPANY, recoveryCompany)
]

function* SMSData(action){
	try{
		const data = yield call(API.getSMSServices, action.payload);
		yield put(actions.SMSDataSuccess(data))
	}catch(error){
		yield put(actions.SMSDataError(error));
	}
}

function* sendCompanyId(action){
	try{
		const data = yield call(API.sendCompanyId, action.payload);
		yield put(actions.sendCompanyIdSuccess(data));
	} catch(error){
		console.log("(sendCorrectDataError CREATECOMP)", error);
		yield put(actions.sendCompanyIdError(error));
	}
}

// function* getSubcompanies(action){
// 	try{
// 		const data = yield call(API.getSubCompaniesData, action.payload);
// 		yield put(actions.getSubcompaniesSuccess(data));
// 	}catch(error){
// 		console.log("(getSubcompaniesError CREATECOMP)", error);
// 		yield put(actions.getSubcompaniesError(error));
// 	}
// }

function* recoveryCompany(action){
	try{
		let data = yield call(API.recoveryCompany, action.payload);
		yield put(actions.successRecoveryCompany(data))
	}catch(error){
		yield put(actions.errorRecoveryCompany(error))
	}
}


function* sendCorrectData(action){
	try{
		const data = yield call(API.sendCorrectData, action.payload);
		yield put(actions.sendCorrectDataSuccess(data));
	} catch(error){
		console.log("(sendCorrectDataError CREATECOMP)", error);
		yield put(actions.sendCorrectDataError(error));
	}
}

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError CREATECOMP)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.payload.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		console.error('(useFilter CREATECOMP) ', error); 
	}
}
  
 
// 3
function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);  
		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error('(createData CREATECOMP) ', error); 
		yield put(actions.createDataError(error))
	}
}
 
// 
function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload);  
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR CREATECOMP) ', error); 
		yield put(actions.deleteDataError(error))
	}
}
 
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR CREATECOMP) ', error); 
		yield put(actions.updateDataError(error))
	}
}
   
// 0: {label: "GeneralIntegrationBean", value: 0}
// 1: {label: "GettTaxiIntegrationBean", value: 2}
// 2: {label: "HazelcastIntegrationBean", value: 3}
// 3: {label: "OISIntegrationBean", value: 4}
// 4: {label: "MaximIntegrationBean", value: 5}
//  
function* requestGetServices(action) {
	try {   
		const data = yield call(API.getServices, action.payload);   
		let contArray = [0,3,2,5,4];
		let resortedData = [];
		while(contArray.length !== 0){
			let current = contArray.shift();
			resortedData.push(data.find(obj=>obj.value===current));
		}

		yield put(actions.getServicesSuccess(resortedData))   
		
	} catch (error) {  
		console.error('(getGetAuditSuccess FOR CREATECOMP) ', error); 
		yield put(actions.getServicesSuccess([]))
	}
}
//  
function* requestGetOneCompany(action) {
	try {   
		const data = yield call(API.getOneCompany, action.payload);    
		yield put(actions.getOneCompanySuccess(data))   
		
	} catch (error) {   
		yield put(actions.getOneCompanySuccess({}))
	}
}
	// отправлять/получать заказы чекбоксами   
function* requestChangeCompanyOrders(action) {
	try {    
		const data = yield call(API.changeCompanyOrders, action.payload.request);    
		yield put(actions.successChangeCompanyOrders(action.payload))   
		
	} catch (error) {    
		yield put(actions.errorChangeCompanyOrders(action.payload))
	}
}
import * as types from './types';

// filter  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_COLUMNS_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_COLUMNS, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_COLUMNS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_COLUMNS, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_COLUMNS_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_COLUMNS_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_COLUMNS, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_COLUMNS_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_COLUMNS_ERROR, error: payload})
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_COLUMNS, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_COLUMNS_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_COLUMNS_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_COLUMNS, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_COLUMNS_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_COLUMNS_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_COLUMNS, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_COLUMNS, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_COLUMNS}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_COLUMNS}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_COLUMNS})   
import axios from 'axios';  
import {delUserInToken, receiveUserFromToken, requestGetCompanyBalance}  from '../../../store/auth/login/actions'; 
import customHistory from '../../../history';
// redux
import store  from '../../../store/store';
import {requestGetModels, requestGetBrands, requestGetRegions, requestGetAutoClassId}  from '../../../store/static/dictionaries/actions';
import { requestGetDataPage as getColumns}  from '../../../store/pages/dictionaries/columnsPage/actions';  

import {setResultChangeUserPassword} from '../../../store/core/connection/actions';
// tools
import JwtTools from '../../../core/utils/other/jwtTools';


export default{  
	/** Запускается в самом начале приложения (если есть токен в  localstorage) или после успешного логина с токеном
	 * Получаем словари, устонавливаем токен в заголовки, помещаем инфо из токена в редакс auth.login.user */ 
	setAuthAndGetInitialData: token => {
		axios.defaults.headers.common['Authorization'] = 'Bearer '+token 
		// устанавливаем токен в начале приложения и тут же получаем данные
		store.dispatch(requestGetModels())
		store.dispatch(requestGetBrands())  
		store.dispatch(requestGetRegions())  
		store.dispatch(requestGetAutoClassId())  
		store.dispatch(getColumns({}))   
		store.dispatch(requestGetCompanyBalance())   
		store.dispatch(receiveUserFromToken(JwtTools.getDataToken(token))) // добавляем инфо из токена в редакс + устонавливаем права
	},
	setSettings: url => {
		axios.defaults.baseURL = url
		axios.defaults.headers['Content-Type'] = 'application/json';
		// если ошибка авторизации - удаляем токен, заголовок и редиректим
		axios.interceptors.response.use(undefined, function (error) {  
			if(error.response.status === 401) {  
				console.warn('401 ERROR AUTH AXIOS!');
				localStorage.removeItem("user");
				delete axios.defaults.headers.common['Authorization']
				store.dispatch(delUserInToken()) // удаляем данные о юзе(токен инфо) в редаксе
				customHistory.push('/login')
			}else if(error.response){
				console.log(" ERROR WITH INTERCEPTOR WITH RESPONSE !!", error.response.status);
				if(error.response.status === 403){
					store.dispatch({
						type: setResultChangeUserPassword.getType(), 
						payload: {
							success: undefined,
							error: "Запрет! Код ошибки 403!"
						}});
				}
			}else if(!error.response){
				console.log(" ERROR WITH INTERCEPTOR WITHOUT RESPONSE !");
			}
			else{ 
				throw (error)
			}
		});
	} 
}
import axios from 'axios' 

let getData = [
	{status: '2', date: '20.04.2019', fio: 'Alex Lol King', nickname: 'pavel', numberPlate: 999, currentDriverId: 141},
	{status: '1', date: '23.04.2019', fio: 'Gert t', nickname: 'pavel', numberPlate: 23, currentDriverId: 5501},
	{status: '0', date: '23.04.2018', fio: 'Ree', nickname: 'ff', numberPlate: 233, currentDriverId: 12}
]

let getRules = [ 
	{'value': '0', 'label': 'Перед', active: false}, 
	{'value': '1', 'label': 'Левый бок', active: true}, 
	{'value': '2', 'label': 'Зад', active: true}, 
	{'value': '3', 'label': 'Правый бок', active: true}, 
	{'value': '4', 'label': 'Открытый багажник', active: true}, 
	{'value': '5', 'label': 'Заднее правое сидение', active: false}, 
	{'value': '6', 'label': 'Переднее правое сидение', active: true}, 
	{'value': '7', 'label': 'Заднее левое сидение', active: true}, 
	{'value': '8', 'label': 'Переднее левое сидение (водителя)', active: true}, 
	{'value': '9', 'label': 'Путевой лист', active: true}, 
]

const getDriverPhotos = [
  {
    label: 'Перед',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Левый бок',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Зад',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'Правый бок',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Открытый багажник',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

// !!! заменить catch на .then(r => r.data),
export default {  
	getData: (filter='') => axios.post(`/photoInspection/list`,  filter).then(r => r.data).catch(() => getData), 
	createData: (data) => axios.post(`/photoInspection/add`, data).then(r => r.data),
	updateData: (data) => axios.post(`/photoInspection/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (payload) => axios.post(`/photoInspection/del/${payload}`).then(r => r.data),

	getRules: (payload) => axios.post(`/photoInspection/rules`, payload).catch(() => getRules),
  changeRules: (payload) => axios.post(`/photoInspection/rules/change`, payload).then(r => r.data),
  changePersonalRules: (payload) => axios.post(`/photoInspection/addIndividual/${payload.id}`, payload.data).then(r => r.data),
	getDriverPhotos: (payload) => axios.post(`/photoInspection/get/${payload.id}`).then(r => r.data),
	agreeDisagreeWidthPhotos: (payload) => axios.post(`/photoInspection/agree/${payload.id}`, payload).then(r => r.data),
}
  
import * as types from './types';

const initialState = {  
	success: '',
	error: false,
	loading: false,
	user: {}, 
	companyBalance: null,
}

export default (state=initialState, action) => {
	  
	switch (action.type) {  

		case types.REQUEST_LOGIN:   
			return {...state, loading: true}  

		case types.SUCCESS_LOGIN:   
			return {...state, success: true, error: false, loading: false}   

		case types.ERROR_LOGIN:   
			return {...state, success: false, error: action.payload, loading: false} 
		
		// - user
		case types.RECEIVE_USER_IN_TOKEN:   
			return {...state, user: action.payload}   
		case types.DEL_USER_IN_TOKEN:   
			return initialState  
		
		// - баланс компании если есть (для создателя companyAdmin) 
		case types.SUCCESS_GET_COMPANYBALANCE_LOGIN:  
		return {...state, companyBalance: action.payload}   

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
 
		default: 
			return state
	}
}
import { call, put, select, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/reports/reportAirport';  
import * as actions from './actions' 
 
export default [ 
	
	takeLatest(actions.requestGetDataPage, requestGetData),    
	
]

// 1
function* requestGetData(action) {
	try {
		const dicts = yield select(state=>state.reports.auditAirport.dicts);
		if(!dicts){
			let reasons = yield call(API.getReasonsAirportDict);
			let events = yield call(API.getEventsAirportDict);
			let keys = {...reasons, ...events};

			reasons = Object.entries(reasons).map(([value, label])=>({label, value}))
			events = Object.entries(events).map(([value, label])=>({label, value}))
			yield put(actions.putDictsForFilter({reasons, events, keys}));
		}

		const data = yield call(API.auditAirport, action.payload);
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {   
		yield put(actions.getDataPageError(error))
	}
}


function* useFilter(action) {
	try {   
		const data = yield call(API.auditAirport, action.payload);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
  
  
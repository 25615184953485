import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/dictionaries/maillist';
import * as types from './types' 
import * as actions from './actions' 
import moment from 'moment';
 
export default [ 
	takeLatest(types.REQUEST_GET_MESSAGES, requestGetData),
	takeLatest(types.REQUEST_CREATE_MESSAGE, createData),
	takeLatest(types.REQUEST_DELETE_MESSAGE, deleteData),
	takeLatest(types.REQUEST_UPDATE_MESSAGE, updateData),
	takeLatest(types.REQUEST_GET_TYPES, getTypes)

]
 
function* requestGetData(action) {
	try {   
		let data = yield call(API.getData, action.payload);
		data = data.map(d=>({...d, cdat: moment(d.cdat).format("YYYY-MM-DD HH:mm")}))
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError drivers)', error);
		yield put(actions.getDataPageError(error))
	}
}

function* createData(action) {
	try {   
		const data = yield call(API.createData, action.payload);    
		yield put(actions.createDataSuccess(data))
		
	} catch (error) {    
		yield put(actions.createDataError(error))
	}
}

function* deleteData(action) {
	try {   
		const data = yield call(API.deleteData, action.payload); 
		
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {  
		console.error('(deleteData FOR maillist) ', error); 
		yield put(actions.deleteDataError(error))
	}
}

// 
function* getOneMessage(action) {
	// try {   
	// 	const data = yield call(API.getOneCar, action.payload);  
	// 	yield put(actions.getOneCarSuccess(data))   
		
	// } catch (error) {  
	// 	console.error('(getOneCar FOR drivers) ', error); 
	// 	yield put(actions.getOneCarSuccess({}))
	// }
}

// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateData, action.payload);  
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {  
		console.error('(updateData FOR maillist) ', error); 
		yield put(actions.updateDataError(error))
	}
}

// 
function* getTypes(action) {
	try {   
		const data = yield call(API.getTypes, action.payload);  
		yield put(actions.successGetTypes(data))   
		
	} catch (error) {  
		console.error('(updateData FOR maillist) ', error); 
		yield put(actions.updateDataError(error))
	}
}

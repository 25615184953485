import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[reportRecieveOrder] requestChangeStatus')
export const requestChangeParamSearch = createAction('[reportRecieveOrder] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[reportRecieveOrder] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportDrivers] changeAnyFilter')

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[reportRecieveOrder] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[reportRecieveOrder] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportRecieveOrder] getDataPageSuccess')
export const getDataPageError = createAction('[reportRecieveOrder] getDataPageError')
// get companies
export const requestGetCompaniesData = createAction('[reportRecieveOrder] requestGetCompaniesData')
export const getCompaniesDataSuccess = createAction('[reportRecieveOrder] getCompaniesDataSuccess')
export const getCompaniesDataError = createAction('[reportRecieveOrder] getCompaniesDataError')
// delete
export const requestDeleteData = createAction('[reportRecieveOrder] requestDeleteData')
export const deleteDataSuccess = createAction('[reportRecieveOrder] deleteDataSuccess')
export const deleteDataError = createAction('[reportRecieveOrder] deleteDataError')
// create
export const sendModalData = createAction('[reportRecieveOrder] sendModalData')
export const sendModalSuccess = createAction('[reportRecieveOrder] sendModalSuccess')
export const sendModalError = createAction('[reportRecieveOrder] sendModalError')
export const requestCreateData = createAction('[reportRecieveOrder] requestCreateData')
export const createDataSuccess = createAction('[reportRecieveOrder] createDataSuccess')
export const createDataError = createAction('[reportRecieveOrder] createDataError') 
// update
export const requestUpdateData = createAction('[reportRecieveOrder] requestUpdateData') 
export const updateDataSuccess = createAction('[reportRecieveOrder] updateDataSuccess') 
export const updateDataError = createAction('[reportRecieveOrder] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[reportRecieveOrder] showWindowAdd') 
export const showWindowUpdate = createAction('[reportRecieveOrder] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[reportRecieveOrder] clearModalFields')
export const clearCreateFields = createAction('[reportRecieveOrder] clearCreateFields') 
export const clearUpdateFields = createAction('[reportRecieveOrder] clearUpdateFields') 
export const clearDeleteFields = createAction('[reportRecieveOrder] clearDeleteFields') 
export const clearRightFilterFields = createAction('[reportRecieveOrder] clearRightFilterFields')

// pagination
export const changePagination = createAction('[reportRecieveOrder] changePagination')

// for window create in reportRecieveOrder
export const addAlias = createAction('[reportRecieveOrder] addAlias')
export const removeAlias = createAction('[reportRecieveOrder] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[reportRecieveOrder] requestGetOneCar')  
export const getOneCarSuccess = createAction('[reportRecieveOrder] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 
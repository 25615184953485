import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_ROLES_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_ROLES_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_ROLES, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_ROLES, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_ROLES_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_ROLES_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_ROLES, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_ROLES_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_ROLES_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_ROLES, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_ROLES_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_ROLES_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_ROLES, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_ROLES_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_ROLES_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_ROLES, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_ROLES, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_ROLES}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_ROLES}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_ROLES})   

// --- Windows request   
//  получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_ROLES, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_ROLES, payload}) 
 
//  получаем компании
export const requestGetCompanies = (payload) => ({type: types.REQUEST_GET_COMPANIES_FOR_ROLES, payload})  
export const successGetCompanies = (payload) => ({type: types.SUCCESS_GET_COMPANIES_FOR_ROLES, payload}) 
//  получаем роли
export const requestGetRoles = (payload) => ({type: types.REQUEST_GET_ROLES_FOR_ROLES, payload})  
export const successGetRoles = (payload) => ({type: types.SUCCESS_GET_ROLES_FOR_ROLES, payload}) 
 
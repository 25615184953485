import axios from 'axios'
import {catchError} from '../apiTools';

export default { 
	getData: (filter) => axios.post(`/mailList/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/mailList/add`, data).then(r => r.data).catch(r => catchError(r)),
	updateData: (payload) => axios.post(`/mailList/upd/${payload.id}`, payload.request).then(r => r.data).catch(r => catchError(r)),
	deleteData: (payload) => axios.post(`/mailList/del/${payload}`).then(r => r.data),
  	getTypes: ()=> axios.post(`/mailList/types`).then(r=>r.data)
} 

import * as actions from "./actions";
import { createReducer } from "redux-act";
import { filterDataForId } from "../../../../core/utils/filters/MainFilters";
import moment from 'moment';

const initialState = {
  filter: {
    startDate: new Date(moment().startOf('day').format()),
    endDate: new Date(),
    nickname: null,
    events: [],
    reasons: [],
    page: 1,
    countPage: 100
  },
  data: [],
  loading: false,
  dicts: null

};

export default createReducer(
  {

    [actions.changeAnyFilter]: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action }
    }),

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, total: action.totalSize, data: action?.list || [] };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),

    [actions.putDictsForFilter]: (state, dicts) => ({
      ...state, dicts
    }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);

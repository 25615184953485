import * as types from './types';


const initialState = { 
	filter: {
		valueSearch: '', 
		page: 1,
		countPage: 100,
	}, 
	data: [],  

	loaded: false, 
	create: { 
		loaded: false,  
		error: false,   
		success: false,   
	},
	update: { 
		loaded: false,  
		error: false,   
		success: false,   
	},
	delete: { 
		loaded: false,  
		error: false,   
		success: false,   
	},
	windowCreateOpened: false, 
	windowUpdateOpened: false,  
	types: []


}

export default (state=initialState, action) => { 
	  
	switch (action.type) {
		// --- filter
		case types.CHANGE_FILTER_MESSAGE:   
			return {...state, loaded: false, filter: {...state.filter, status: action.payload}}

		case types.GET_DATA_MESSAGES_SUCCESS:
			return {...state, loaded: true, data: action.payload}

		case types.CREATE_MESSAGE_SUCCESS:
			return {...state, create: {loaded: false, error: false, success: true}}

		case types.GET_TYPES_SUCCESS:
			return {...state, types: action.payload}
		
		// case types.CHANGE_PAGIN_MESSAGES:
		// 	return {...state, }

		case types.CLEAR_PROGRESS_DATA:
			return {...state, 
				create: {loaded: false, error: false, success: false},
				update: {loaded: false, error: false, success: false},
				delete: {loaded: false, error: false, success: false}
			}


		case types.SHOW_WINDOW_ADD_MESSAGE:    
			return {...state, windowCreateOpened: action.payload, } 
		case types.SHOW_WINDOW_UPDATE_MESSAGE:    
			return {...state, windowUpdateOpened: action.payload, } 

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState 
		 
		default: 
			return state
	}
}
 
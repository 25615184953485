import CreateCompanyPage from './CreateCompanyPage/saga';  
import CreateUserPage from './CreateUserPage/saga';  
import SettingsPage from './SettingsPage/saga';  
import RolesPage from './RolesPage/saga';  
import TariffEditPage  from './TariffEditPage/saga'; 
import RegionsPage  from './RegionsPage/saga'; 
import PartnersPage  from './PartnersPage/saga'; 
import ParkServicesPage  from './ParkServicesPage/saga'; 
import SettingsSystemPage from './SettingsSystemPage/saga';
 
export default [ 
  ...CreateCompanyPage,  
  ...CreateUserPage,  
  ...SettingsPage,  
  ...RolesPage,  
  ...TariffEditPage,  
  ...RegionsPage,  
  ...PartnersPage,  
  ...ParkServicesPage,
  ...SettingsSystemPage
]
 
import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[reportDrivers] requestChangeStatus')
export const requestChangeParamSearch = createAction('[reportDrivers] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[reportDrivers] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportDrivers] changeAnyFilter')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[reportDrivers] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[reportDrivers] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportDrivers] getDataPageSuccess')
export const getDataPageError = createAction('[reportDrivers] getDataPageError')
// delete
export const requestDeleteData = createAction('[reportDrivers] requestDeleteData')
export const deleteDataSuccess = createAction('[reportDrivers] deleteDataSuccess')
export const deleteDataError = createAction('[reportDrivers] deleteDataError')
// create
export const requestCreateData = createAction('[reportDrivers] requestCreateData')
export const createDataSuccess = createAction('[reportDrivers] createDataSuccess')
export const createDataError = createAction('[reportDrivers] createDataError') 
// update
export const requestUpdateData = createAction('[reportDrivers] requestUpdateData') 
export const updateDataSuccess = createAction('[reportDrivers] updateDataSuccess') 
export const updateDataError = createAction('[reportDrivers] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[reportDrivers] showWindowAdd') 
export const showWindowUpdate = createAction('[reportDrivers] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = createAction('[reportDrivers] clearCreateFields') 
export const clearUpdateFields = createAction('[reportDrivers] clearUpdateFields') 
export const clearDeleteFields = createAction('[reportDrivers] clearDeleteFields')  

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[reportDrivers] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[reportDrivers] getGetAuditSuccess')   
 
import * as types from './types';

// filter

export const requestChangeFilter = (payload, filterData) => ({type: types.CHANGE_FILTER_MESSAGE, payload, filterData}) 

export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_MESSAGE, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_MESSAGES, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_MESSAGES_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_MESSAGES_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_MESSAGE, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_MESSAGE_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_MESSAGE_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_MESSAGE, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_MESSAGE_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_MESSAGE_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_MESSAGE, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_MESSAGE_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_MESSAGE_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_MESSAGE, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_MESSAGE, payload}) 

export const changePagination = (payload) => ({type: types.CHANGE_PAGIN_MESSAGES, payload}) 

export const getTypes = (payload) => ({type: types.REQUEST_GET_TYPES, payload}) 
export const successGetTypes = (payload) => ({type: types.GET_TYPES_SUCCESS, payload}) 

export const clearProgressData = ()=> ({type: types.CLEAR_PROGRESS_DATA})
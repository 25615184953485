import {call, put, takeLatest} from 'redux-saga/effects';
import API from "../../../../api/finances/transactionsPage";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.requestCompaniesData, requestCompaniesData)
]

function* requestCompaniesData(action){
  try{
    const data = yield call(API.getCompaniesData, action.payload);
    yield put(actions.companiesDataSuccess(data));
  } catch(error){
    console.log("transactionsPage Error", error);
    yield put(actions.companiesDataError(error));
  }
}

function* requestGetData(action){
  try{
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch(error){
    console.log("(getDataPageError transactions)", error);
    yield put(actions.getDataPageError(error));
  }
}

function* useFilter(action){
  try{
    const data = yield call(API.getData, action.payload.filterData);
    yield put(actions.useFilterSuccess(data));
  } catch(error){
    yield put(actions.useFilterSuccess([]))
  }
}
import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[reportCarsOnline] requestChangeStatus')
export const requestChangeParamSearch = createAction('[reportCarsOnline] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[reportCarsOnline] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportCarsOnline] changeAnyFilter')

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[reportCarsOnline] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[reportCarsOnline] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportCarsOnline] getDataPageSuccess')
export const getDataPageError = createAction('[reportCarsOnline] getDataPageError')
// get companies
export const requestGetCompaniesData = createAction('[reportCarsOnline] requestGetCompaniesData')
export const getCompaniesDataSuccess = createAction('[reportCarsOnline] getCompaniesDataSuccess')
export const getCompaniesDataError = createAction('[reportCarsOnline] getCompaniesDataError')
//get regions
export const requestGetRegionsData = createAction('[reportCarsOnline] requestGetRegionsData')
export const getRegionsDataSuccess = createAction('[reportCarsOnline] getRegionsDataSuccess')
export const getRegionsDataError = createAction('[reportCarsOnline] getRegionsDataError')
// delete
export const requestDeleteData = createAction('[reportCarsOnline] requestDeleteData')
export const deleteDataSuccess = createAction('[reportCarsOnline] deleteDataSuccess')
export const deleteDataError = createAction('[reportCarsOnline] deleteDataError')
// create
export const sendModalData = createAction('[reportCarsOnline] sendModalData')
export const sendModalSuccess = createAction('[reportCarsOnline] sendModalSuccess')
export const sendModalError = createAction('[reportCarsOnline] sendModalError')
export const requestCreateData = createAction('[reportCarsOnline] requestCreateData')
export const createDataSuccess = createAction('[reportCarsOnline] createDataSuccess')
export const createDataError = createAction('[reportCarsOnline] createDataError') 
// update
export const requestUpdateData = createAction('[reportCarsOnline] requestUpdateData') 
export const updateDataSuccess = createAction('[reportCarsOnline] updateDataSuccess') 
export const updateDataError = createAction('[reportCarsOnline] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[reportCarsOnline] showWindowAdd') 
export const showWindowUpdate = createAction('[reportCarsOnline] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[reportCarsOnline] clearModalFields')
export const clearCreateFields = createAction('[reportCarsOnline] clearCreateFields') 
export const clearUpdateFields = createAction('[reportCarsOnline] clearUpdateFields') 
export const clearDeleteFields = createAction('[reportCarsOnline] clearDeleteFields') 
export const clearRightFilterFields = createAction('[reportCarsOnline] clearRightFilterFields')

// pagination
export const changePagination = createAction('[reportCarsOnline] changePagination')

// for window create in reportCarsOnline
export const addAlias = createAction('[reportCarsOnline] addAlias')
export const removeAlias = createAction('[reportCarsOnline] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[reportCarsOnline] requestGetOneCar')  
export const getOneCarSuccess = createAction('[reportCarsOnline] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 
import reportManager from './reportManager/saga';
import reportGiveOrder from "./reportGiveOrder/saga"; 
import reportRecieveOrder from "./reportRecieveOrder/saga"; 
import reportAgent from "./reportAgent/saga";
import reportCarsOnline from "./reportCarsOnline/saga";
import reportExecutedOrders from "./reportExecutedOrders/saga";
import reportPhotoinspection from "./reportPhotoinspection/saga";
import auditAirport from "./auditAirport/saga";
import {saga as reportTitle} from "./reportTitle";

export default [ 
  ...reportManager,
  ...reportGiveOrder,
  ...reportRecieveOrder,
  ...reportAgent,
  ...reportCarsOnline,
  ...reportExecutedOrders,
  ...reportPhotoinspection,
  ...auditAirport,
  ...reportTitle
]
 
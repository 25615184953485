import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; 
// redux
import {Provider} from 'react-redux';
import store from './store/store'; 
// styles 
import theme from './ui/theme';
import { ThemeProvider, StylesProvider } from '@material-ui/styles'; 
// tools
import AxiosDefault from './core/utils/axios/AxiosDefault'; 
// import SetAppOptions from './core/utils/settings/SetAppOptions'; 
 
 
if(process.env.NODE_ENV !== 'production'){
	AxiosDefault.setSettings(process.env.REACT_APP_DEV_URL) 
} else {  
	AxiosDefault.setSettings(process.env.REACT_APP_PROD_URL)
}

if(localStorage.user){  
	try { 
		let token = localStorage.user 
		AxiosDefault.setAuthAndGetInitialData(token)  
	} catch (error) { }
} 

// new SetAppOptions() // !Пока ничего не ставим устонавливаем настройки приложения (как высота браузера)

ReactDOM.render(
<Provider store={store}>
	<StylesProvider injectFirst>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</StylesProvider>
</Provider>, document.getElementById('root'));
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import './core/styles/App.scss'; //  разобраться
import './core/styles/index.sass'; //  как основные
import Private  from './core/Private';
import customHistory from './history';
// redux 
import {connect} from 'react-redux';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

/**
 * Асихронный импорт-react-loadable: страниц ошибок, auth, DefaultLayout(содержится все наше app)
 */
// Containers
const DefaultLayout = Loadable({
    loader: () => import('./core/containers/DefaultLayout'),
    loading
});

// Pages
const Login = Loadable({
    loader: () => import('./core/Pages/Login/Login'),
    loading
});

const RecoveryPass = Loadable({
    loader: () => import('./core/Pages/Login/RecoveryPassword'),
    loading
});

const ChangePassword = Loadable({
    loader: () => import('./core/Pages/Login/ChangePassword'),
    loading
});

const Page404 = Loadable({
    loader: () => import('./core/Pages/Page404/Page404'),
    loading
});

const Page500 = Loadable({
    loader: () => import('./core/Pages/Page500/Page500'),
    loading
});

const Page403 = Loadable({
    loader: () => import('./core/Pages/Page403/Page403'),
    loading
});



class App extends Component {
 
  render() {
    return (
      <Router history={customHistory}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/recoveryPassword" name="Recovery pass" component={RecoveryPass} />
          <Route exact path="/changePassword" name="Recovery pass" component={ChangePassword} />
          <Route exact path="/403" name="Page 403" component={Page403} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          {/*  все вызовы передаем на этот компонент - Private. Он распетушевывает все внутри
              + проверяет токен но лишь в локалсторадж и что не нужно менять пароль */}
          <Private path="/" name="Home" component={DefaultLayout} /> 
        </Switch>
      </Router>
    );
  }
} 
 
export default connect(
  null, 
)(App);


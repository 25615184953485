import { call, put, takeLatest } from 'redux-saga/effects';
// import API from '../../../../api/controlRoom/map'; 
import CmpAPI from '../../../api/admin/creatingCompany';
import UsrAPI from '../../../api/admin/creatingUser';
import * as actions from './actions' 
 

export default [ 
	// takeLatest(actions.requestGetDataPage, requestGetData),
	takeLatest(actions.loadUserProfileData, loadUserProfileData),
	takeLatest(actions.changeUserPassword, changeUserPassword)

]


function* loadUserProfileData(action){

	try{
		let result = yield call(CmpAPI.getOneCompany, action.payload);
		if(result){
			yield put(actions.successUserProfileData(result));
		}
	}catch(error){
		console.error('error load user profile data', error);
	}
}


function* changeUserPassword(action){
	try{
		let result = yield call(UsrAPI.changePassword, action.payload);
		if(result){
			if(result.status === 200){
				yield put(actions.toggleUserProfile({visible: false}));
				yield put(actions.setResultChangeUserPassword({error: undefined, success: "Пароль успешно изменен!"}))
			}
		}
	}catch(error){
		console.log("Change user password exception!", error);
	}
}


// 1
// function* requestGetData(action) {
// 	try {   
// 		const data = yield call(API.getData, action.payload);    
// 		yield put(actions.getDataPageSuccess(data))
		
// 	} catch (error) {  
// 		console.error('(getDataPageError MAP)', error);
// 		yield put(actions.getDataPageError(error))
// 	}
// } 
import axios from 'axios'
import {catchError} from '../apiTools';

export default {  
	getData: (filter) => axios.post(`/unIdentifiedObjects/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/unIdentifiedObjects/add`, data).then(r => r.data).catch(r => catchError(r)),  
	updateData: (data) => axios.post(`/unIdentifiedObjects/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/unIdentifiedObjects/del/${data.id}`).then(r => r.data),
	getDataForTable: (data) => axios.post('/unIdentifiedObjects/autoModels', data).then(r=>r.data),
	sendTableData: (data)=> axios.post('/unIdentifiedObjects/recognize', data).then(r=>r.data)
}
	 
import { createReducer } from 'redux-act';
import * as actions from './actions'; 

const initialState = {    
  sidebarActive: true,  // открыт ил сайдбар слева
  
  // настройки нового открывающегося окна с дефолтом
  newWindowSettings: {height:  window.screen.height < 900 ? 550 : 800, width: 1000, centerscreen: true}, 
  showUserProfile: {visible: false, mode: "profile"},
  profileData: null,
  resultChangeUserPassword: {
    success: undefined,
    error: undefined
  }
} 

/** 
 * Обеспечиваем глобальный стейт внутри приложения. компонентами которым нужен
*/
export default createReducer({ 
  [actions.showSidebar]: (state, payload) => ({...state, sidebarActive: payload !== 'undefined' ? payload : !state.sidebarActive}), 
  // [actions.changeSetting]: (state, payload) => ({...state, payload}),
  [actions.toggleUserProfile]: (state, payload)=>({...state, showUserProfile: {...state.showUserProfile, ...payload}}),
  [actions.successUserProfileData]: (state, payload)=>({...state, profileData: payload}),
  [actions.setResultChangeUserPassword]: (state, payload)=>({...state, resultChangeUserPassword: payload}),
  'LOGOUT_FROM_SYSTEM': (state, payload) => initialState,
}, initialState);  
 
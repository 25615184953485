import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[reportGiveOrder] requestChangeStatus')
export const requestChangeParamSearch = createAction('[reportGiveOrder] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[reportGiveOrder] requestChangeRightFilter')
export const changeAnyFilter = createAction('[reportGiveOrder] changeAnyFilter')

// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[reportGiveOrder] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[reportGiveOrder] requestGetDataPage')
export const getDataPageSuccess = createAction('[reportGiveOrder] getDataPageSuccess')
export const getDataPageError = createAction('[reportGiveOrder] getDataPageError')
// get companies
export const requestGetCompaniesData = createAction('[reportGiveOrder] requestGetCompaniesData')
export const getCompaniesDataSuccess = createAction('[reportGiveOrder] getCompaniesDataSuccess')
export const getCompaniesDataError = createAction('[reportGiveOrder] getCompaniesDataError')
// delete
export const requestDeleteData = createAction('[reportGiveOrder] requestDeleteData')
export const deleteDataSuccess = createAction('[reportGiveOrder] deleteDataSuccess')
export const deleteDataError = createAction('[reportGiveOrder] deleteDataError')
// create
export const sendModalData = createAction('[reportGiveOrder] sendModalData')
export const sendModalSuccess = createAction('[reportGiveOrder] sendModalSuccess')
export const sendModalError = createAction('[reportGiveOrder] sendModalError')
export const requestCreateData = createAction('[reportGiveOrder] requestCreateData')
export const createDataSuccess = createAction('[reportGiveOrder] createDataSuccess')
export const createDataError = createAction('[reportGiveOrder] createDataError') 
// update
export const requestUpdateData = createAction('[reportGiveOrder] requestUpdateData') 
export const updateDataSuccess = createAction('[reportGiveOrder] updateDataSuccess') 
export const updateDataError = createAction('[reportGiveOrder] updateDataError') 
// показ окон
export const showWindowAdd = createAction('[reportGiveOrder] showWindowAdd') 
export const showWindowUpdate = createAction('[reportGiveOrder] showWindowUpdate') 
// приводим все в начальное состояние при закрытии окна
export const clearModalFields = createAction('[reportGiveOrder] clearModalFields')
export const clearCreateFields = createAction('[reportGiveOrder] clearCreateFields') 
export const clearUpdateFields = createAction('[reportGiveOrder] clearUpdateFields') 
export const clearDeleteFields = createAction('[reportGiveOrder] clearDeleteFields') 
export const clearRightFilterFields = createAction('[reportGiveOrder] clearRightFilterFields')

// pagination
export const changePagination = createAction('[reportGiveOrder] changePagination')

// for window create in reportGiveOrder
export const addAlias = createAction('[reportGiveOrder] addAlias')
export const removeAlias = createAction('[reportGiveOrder] removeAlias')

//  получаем всю информацию об 1 машине
export const requestGetOneCar = createAction('[reportGiveOrder] requestGetOneCar')  
export const getOneCarSuccess = createAction('[reportGiveOrder] getOneCarSuccess') 
 

// --- Windows request   
//  получаем аудит
// export const requestGetAudit = createAction('[clientsBlackList] requestGetAudit')   
// export const getGetAuditSuccess = createAction('[clientsBlackList] getGetAuditSuccess')   
 
import { createAction } from 'redux-act';
import { call, put, takeLatest } from "redux-saga/effects";

import { createReducer } from "redux-act";

import moment from 'moment';

import API from "../../../../api/reports/reportsTitle";


const LOAD_STATES = {0: "NOT_LOADED", 1: "LOADING", 2: "LOADED"};

const requestGetTitleData = createAction('requestGetTitleData');
const successGetTitleData = createAction('requestGetTitleData');

const successAverageCheck = createAction('successaverageCheck');
const successOrderProcessingRevenue = createAction('successOrderProcessingRevenue');
const successSystemOrderData = createAction('successSystemOrderData');
const successTariffShares = createAction('successTariffShares');
const setLoadState = createAction('setLoadState');

const changeFilterDates = createAction('changeFilterDates');


const apiCpomlx = [
    [API.averageCheck, successAverageCheck, null],
    [API.orderProcessingRevenue, successOrderProcessingRevenue, {whose: null}],
    [API.systemOrderData, successSystemOrderData, null],
    [API.tariffShares, successTariffShares, null]
];


const actions = {requestGetTitleData, successGetTitleData, changeFilterDates};


const initialState = {
    filter: {
        startDate: moment().subtract(1, "months").toISOString(), // .format("dd-MM-уууу"),
        endDate: moment().toISOString(),
        regionId: 0,
        whose: "ANY"
    },
    loadState: 0,

};


const reducer = createReducer({
    [successGetTitleData]: (state, payload)=>({...state}),

    [successOrderProcessingRevenue]: (state, payload)=>({...state, orderProcessingRevenue: {...payload}}),
    [successSystemOrderData]: (state, payload)=>({...state, systemOrderData: {...payload}}),
    [successAverageCheck]: (state, payload)=>({...state, averageCheck: {...payload}}),
    [successTariffShares]: (state, payload)=>({...state, tariffShares: {...payload}}),
    
    [setLoadState]: (state, loadState)=>({...state, loadState}),
    [changeFilterDates]: (state, payload)=>({...state, filter: payload}),
}, initialState);


const saga = [takeLatest(requestGetTitleData, getTitleData)];

function* getTitleData(action){

    for(let [actApi, actSucc, filterOverride] of apiCpomlx){
        yield put(setLoadState(1));
        try{
            let fitlerActive = {...action.payload};
            if(filterOverride) Object.assign(fitlerActive, filterOverride);
            let data = yield call(actApi, fitlerActive);
            if(data){
                yield put(actSucc(data));
            }

        }catch(error){
            console.log("getTitleData error", error)
        }finally{
            yield put(setLoadState(2))
        }
    }

}


export {reducer, saga, actions};
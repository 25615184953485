// filter
export const REQUEST_CHANGE_CLIENTS_STATUS = 'REQUEST_CHANGE_CLIENTS_STATUS'
export const REQUEST_CHANGE_CLIENTS_SEARCH = 'REQUEST_CHANGE_CLIENTS_SEARCH'
export const CHANGE_RIGHT_FILTER_CLIENTS = 'CHANGE_RIGHT_FILTER_CLIENTS' 
export const USE_FILTER_SUCCESS_CLIENTS = 'USE_FILTER_SUCCESS_CLIENTS' 
export const CHANGE_ANY_FILTER_CLIENTS = 'CHANGE_ANY_FILTER_CLIENTS' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_CLIENTS = 'REQUEST_GET_CLIENTS'
export const GET_DATA_CLIENTS_SUCCESS = 'GET_DATA_CLIENTS_SUCCESS' 
export const GET_DATA_CLIENTS_ERROR = 'GET_DATA_CLIENTS_ERROR'  
// показ окон
export const SHOW_WINDOW_ADD_CLIENTS = 'SHOW_WINDOW_ADD_CLIENTS' 
export const SHOW_WINDOW_UPDATE_CLIENTS = 'SHOW_WINDOW_UPDATE_CLIENTS'    
// очищение реквестов
export const CLEAR_CREATE_FIELDS_CLIENTS = 'CLEAR_CREATE_FIELDS_CLIENTS' 
export const CLEAR_UPDATE_FIELDS_CLIENTS = 'CLEAR_UPDATE_FIELDS_CLIENTS'  
export const CLEAR_DELETE_FIELDS_CLIENTS = 'CLEAR_DELETE_FIELDS_CLIENTS'  


// --- Windows request 
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_CLIENTS = 'REQUEST_GET_AUDIT_FOR_CLIENTS'  
export const SUCCESS_GET_AUDIT_FOR_CLIENTS = 'SUCCESS_GET_AUDIT_FOR_CLIENTS'  
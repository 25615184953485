import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../../api/reports/reportAgent";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestCreateData, createData),
  takeLatest(actions.requestDeleteData, deleteData),
  takeLatest(actions.requestUpdateData, updateData),
  takeLatest(actions.requestChangeStatus, useFilter),
  takeLatest(actions.requestChangeParamSearch, useFilter),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.requestGetCompaniesData, requestCompanies)
];


function* requestCompanies(action){
  try{
    const data = yield call(API.getCompanies, action.payload);
    yield put(actions.getCompaniesDataSuccess(data));
  }catch(error){
    console.log("getCompanies ERROR, reportAgent", error);
    yield put(actions.getCompaniesDataError(error));
  }
}


// 1
function* requestGetData(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    console.error("(getDataPageError reportAgent)", error);
    yield put(actions.getDataPageError(error));
  }
}

//
function* useFilter(action) {
  try {
    const data = yield call(API.downloadFile, action.payload.filterData);

    yield put(actions.useFilterSuccess(data));
  } catch (error) {
    yield put(actions.useFilterSuccess([]));
  }
}

//
function* createData(action) {
  try {
    const data = yield call(API.createData, action.payload);

    yield put(actions.createDataSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.createDataError(error));
  }
}

//
function* deleteData(action) {
  try {
    const data = yield call(API.deleteData, action.payload);
    yield put(actions.deleteDataSuccess(data));
  } catch (error) {
    console.error("(deleteData for reportAgent) ", error);
    yield put(actions.deleteDataError(error));
  }
}

//
function* updateData(action) {
  try {
    const data = yield call(API.updateData, action.payload);

    yield put(actions.updateDataSuccess(data));
  } catch (error) {
    console.error("(updateData for reportAgent) ", error);
    yield put(actions.updateDataError(error));
  }
}

 
// update 
export const REQUEST_UPDATE_SETTINGAPP = 'REQUEST_UPDATE_SETTINGAPP'
export const UPDATE_SETTINGAPP_SUCCESS = 'UPDATE_SETTINGAPP_SUCCESS' 
export const UPDATE_SETTINGAPP_ERROR = 'UPDATE_SETTINGAPP_ERROR'  
export const CLEAR_UPDATE_FIELDS_SETTINGAPP = 'CLEAR_UPDATE_FIELDS_SETTINGAPP'    


// --- Windows request  
//  получаем всю информацию об 1 компании
export const REQUEST_GET_COMPANY_FOR_SETTINGAPP = 'REQUEST_GET_COMPANY_FOR_SETTINGAPP' 
export const SUCCESS_GET_COMPANY_FOR_SETTINGAPP = 'SUCCESS_GET_COMPANY_FOR_SETTINGAPP'  
 
export const LOAD_SHOW_PRICE = 'LOAD_SHOW_PRICE'
export const SUCCESS_LOAD_SHOW_PRICE = 'SUCCESS_LOAD_SHOW_PRICE'
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/controlRoom/pilot_debt';  
import * as actions from './actions' 
 
export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestCreateData, createData),    
	takeLatest(actions.requestDeleteData, deleteData),    
	// takeLatest(actions.requestUpdateData, updateData),  
  takeLatest(actions.requestChangeStatus, useFilter),   
  takeLatest(actions.requestChangeParamSearch, useFilter),   
	takeLatest(actions.requestChangeRightFilter, useFilter),  
	
	takeLatest(actions.requestGetDataCancStatistics, getDataCancelStatistic)
]

// 1
function* requestGetData(action) {
	try { 
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError BLACKLIST)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 
function* useFilter(action) {
	try {    
		const data = yield call(API.getData, action.payload.filterData);
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
  
 
// 
function* createData(action) {
	try {   
		yield put(actions.createInProgress(true));
		const data = yield call(API.createData, action.payload.payload); 

		yield put(actions.createDataSuccess(data))
		
	} catch (error) { 
		console.error(error);
		yield put(actions.createDataError(error))  
	}
} 
 
// 
function* deleteData(action) {
	try {   

		yield put(actions.deleteInProgress(true))
		const data = yield call(API.deleteData, action.payload);   
		yield put(actions.deleteDataSuccess(data))   
		
	} catch (error) {   
		console.error('(deleteData FOR BLACKLIST) ', error); 
		yield put(actions.deleteDataError(error))
	}
}


function* getDataCancelStatistic(action) {
	try {   
		yield put(actions.setLoadingIndicator(true))
		const data = yield call(API.getDataCanceledStatistics, action.payload);   
		yield put(actions.successGetDataCancStatistics(data))   
		
	} catch (error) {   
		console.error('(deleteData FOR BLACKLIST) ', error); 
		yield put(actions.deleteDataError(error))
	}finally{
		yield put(actions.setLoadingIndicator(false))
	}
}


 
// // 
// function* updateData(action) {
// 	try {   
// 		const data = yield call(API.updateData, action.payload);  
// 		yield put(actions.updateDataSuccess(data))   
		
// 	} catch (error) {  
// 		console.error('(updateData FOR BLACKLIST) ', error); 
// 		yield put(actions.updateDataError(error))
// 	}
// }
   
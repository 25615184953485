import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/admin/settingsSystem';   
import * as types from './types' 
import * as actions from './actions' 
 
export default [      
	takeLatest(types.REQUEST_UPDATE_SYSTEMSETTINGS, updateData),     
	takeLatest(types.REQUEST_GET_SYSTEMSETTINGS, requestSysSettings),    
]
 
// 
function* updateData(action) {
	try {   
		const data = yield call(API.updateSystemSettings, action.payload);
		yield put(actions.updateDataSuccess(data))   
		
	} catch (error) {     
		yield put(actions.updateDataError(true))
	}
}
    
//  
function* requestSysSettings(action) {
	try {
		const smsServices = yield call(API.loadSmsServices, action.payload);
		yield put(actions.loadSmsServices(smsServices));
		const data = yield call(API.getSystemSettings, action.payload);
		yield put(actions.getSystemSettingsSuccess(data));
		
	} catch (error) {   
		yield put(actions.getSystemSettingsSuccess({}));
	}
}
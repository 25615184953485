import { createAction } from 'redux-act';



export const changeAnyFilter = createAction('[auditAirport] changeAnyFilter')
export const putDictsForFilter = createAction('[auditAirport] putDictsForFilter')

// get
export const requestGetDataPage = createAction('[auditAirport] requestGetDataPage')
export const getDataPageSuccess = createAction('[auditAirport] getDataPageSuccess')
export const getDataPageError = createAction('[auditAirport] getDataPageError')



export const useFilterSuccess = createAction('[auditAirport] useFilterSuccess')
 


import * as types from './types'; 

// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_SYSTEMSETTINGS, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_SYSTEMSETTINGS_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_SYSTEMSETTINGS_ERROR, error: payload})
export const clearUpdateFields = (payload) => ({type: types.CLEAR_UPDATE_FIELDS_SYSTEMSETTINGS, payload})  

// --- Windows request      
export const requestGetSystemSettings = (payload) => ({type: types.REQUEST_GET_SYSTEMSETTINGS, payload})  
export const getSystemSettingsSuccess = (payload) => ({type: types.SUCCESS_GET_SYSTEMSETTINGS, payload})
export const loadSmsServices = (payload) => ({type: types.LOAD_SMS_SERVICES, payload})

import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_CREATEUSER_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_CREATEUSER_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_CREATEUSER, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_CREATEUSER, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_CREATEUSER, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_CREATEUSER_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_CREATEUSER_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_CREATEUSER, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_CREATEUSER_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_CREATEUSER_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_CREATEUSER, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_CREATEUSER_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_CREATEUSER_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_CREATEUSER, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_CREATEUSER_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_CREATEUSER_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_CREATEUSER, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_CREATEUSER, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_CREATEUSER}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_CREATEUSER}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_CREATEUSER})   

// --- Windows request   
//  получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_CREATEUSER, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_CREATEUSER, payload}) 
 
//  получаем компании
export const requestGetCompanies = (payload) => ({type: types.REQUEST_GET_COMPANIES_FOR_CREATEUSER, payload})  
export const successGetCompanies = (payload) => ({type: types.SUCCESS_GET_COMPANIES_FOR_CREATEUSER, payload}) 
//  получаем роли
export const requestGetRoles = (payload) => ({type: types.REQUEST_GET_ROLES_FOR_CREATEUSER, payload})  
export const successGetRoles = (payload) => ({type: types.SUCCESS_GET_ROLES_FOR_CREATEUSER, payload}) 
 
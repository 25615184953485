import * as types from './types';
import {filterDataForId} from '../../../../core/utils/filters/MainFilters'; 
import dict from '../../../../core/dict/controlRoom/blacklist'; 
 

const initialState = { 
	filter: {
		status: dict.status[0].value,  
		paramSearch: dict.params[0].value,  
		valueSearch: '',  
		rightFilter: { 
		 
		}, 
	}, 
	data: [],  
	loading: false,  
	create: { // для работы при создании 
		loading: false,  
		error: false,   
		success: false,   
	},
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	delete: { // при удалении
		loading: false,  
		error: false,   
		success: false,   
	},   
	audit: {
		data: [], 
		loading: true
	}, // журнал 
 
}

export default (state=initialState, action) => {
	  
	switch (action.type) {
		// --- filter
		case types.REQUEST_CHANGE_DRIVERBLCKLST_STATUS:   
			return {...state, loading: true, filter: {...state.filter, status: action.payload}}

		case types.REQUEST_CHANGE_DRIVERBLCKLST_SEARCH: 
			return {...state, loading: true, filter: {...state.filter, paramSearch: action.payload.paramSearch, valueSearch: action.payload.valueSearch}}

		case types.CHANGE_RIGHT_FILTER_DRIVERBLCKLST:  
			return {...state, loading: true, filter: {...state.filter, rightFilter: action.payload}}

		case types.USE_FILTER_SUCCESS_CLIENTS:    
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.CHANGE_ANY_FILTER_DRIVERBLCKLST:   // non async  
			return {...state,  filter: {...state.filter, ...action.payload}} 
 

		// --- Async get ->>
		case types.REQUEST_GET_DRIVERBLCKLST: 
			return {...state, loading: true}

		case types.GET_DATA_DRIVERBLCKLST_SUCCESS:   
			return {...state, loading: false, data: filterDataForId(action.payload)} 

		case types.GET_DATA_DRIVERBLCKLST_ERROR: 
			return {...state, loading: false, data: [], error: action.payload} 


		// --- Async create ->>
		case types.REQUEST_CREATE_DRIVERBLCKLST: 
			return {...state, create: {...state.create, loading: true}}  
		case types.CREATE_DRIVERBLCKLST_SUCCESS: 
			let newData = state.data.concat(action.payload) 
			return {...state, create: {...state.create, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.CREATE_DRIVERBLCKLST_ERROR: 
			return {...state, create: {...state.create, loading: false, error: action.error, success: false}}  
		case types.CLEAR_CREATE_FIELDS_DRIVERBLCKLST: 
			return {...state, create: {...state.create, loading: false, error: false, success: false}} // initial  

		// --- Async update data ->>
	case types.REQUEST_UPDATE_DRIVERBLCKLST: 
		return {...state, update: {...state.update, loading: true}}  
	case types.UPDATE_DRIVERBLCKLST_SUCCESS: 
			newData = state.data.map(el => { 
			if(el.id === action.payload.id) el = action.payload
			return el
		})
		return {...state, update: {...state.update, loading: false, success: true, error: false}, data: newData}  
	case types.UPDATE_DRIVERBLCKLST_ERROR: 
		return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
	case types.CLEAR_UPDATE_FIELDS_DRIVERBLCKLST: 
		return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
 
 

		// --- Async delete data ->>
		case types.REQUEST_DELETE_DRIVERBLCKLST:  
			return {...state, delete: {...state.delete, loading: true}}  
		case types.DELETE_DRIVERBLCKLST_SUCCESS:   
			newData = newData = state.data.filter(el=> String(el.id) !== String(action.payload))  
			return {...state, delete: {...state.delete, loading: false, success: true, error: false}, data: filterDataForId(newData)}  
		case types.DELETE_DRIVERBLCKLST_ERROR: 
			return {...state, delete: {...state.delete, loading: false, error: action.error, success: false}}  
		case types.CLEAR_DELETE_FIELDS_DRIVERBLCKLST: 
			return {...state, delete: {...state.delete, loading: false, error: false, success: false}} // initial 


		// показ окон
		case types.SHOW_WINDOW_ADD_DRIVERBLCKLST:    
			return {...state, windowCreateOpened: action.payload} 
		case types.SHOW_WINDOW_UPDATE_DRIVERBLCKLST:    
			return {...state, windowUpdateOpened: action.payload} 
  
		 
		// --- WINDOWS request ->>   
		// показ окон для создание апдейта пассажиров 
		case types.SHOW_WINDOW_PASSENGERS_ADD_DRIVERBLCKLST:  
			return {...state, passengersFields: {...state.passengersFields, modalCreateOpened: action.payload}}  
		case types.SHOW_WINDOW_PASSENGERS_UPDATE_DRIVERBLCKLST:  
			return {...state, passengersFields: {...state.passengersFields, modalUpdateOpened: action.payload}}  

		// получаем журнал
		case types.REQUEST_GET_AUDIT_FOR_DRIVERBLCKLST:  
			return {...state, audit: {...state.audit, loading: true}}  
		case types.SUCCESS_GET_AUDIT_FOR_DRIVERBLCKLST:  
			return {...state, audit: {...state.audit, loading: false, data: action.payload}}  
 
		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
			 
		default: 
			return state
	}
}
 
import * as types from './types'; 

// filter
export const requestChangeStatus = (payload, filterData) => ({type: types.REQUEST_CHANGE_DRIVERBLCKLST_STATUS, payload, filterData})  
export const requestChangeParamSearch = (payload, filterData) => ({type: types.REQUEST_CHANGE_DRIVERBLCKLST_SEARCH, payload, filterData}) 
export const requestChangeRightFilter = (payload, filterData) => ({type: types.CHANGE_RIGHT_FILTER_DRIVERBLCKLST, payload, filterData}) 
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess = (payload) => ({type: types.USE_FILTER_SUCCESS_CLIENTS, payload})  
// get
export const requestGetDataPage = (payload) => ({type: types.REQUEST_GET_DRIVERBLCKLST, payload})
export const getDataPageSuccess = (payload) => ({type: types.GET_DATA_DRIVERBLCKLST_SUCCESS, payload: payload})
export const getDataPageError = (payload) => ({type: types.GET_DATA_DRIVERBLCKLST_ERROR, error: payload})
// delete
export const requestDeleteData = (payload) => ({type: types.REQUEST_DELETE_DRIVERBLCKLST, payload})
export const deleteDataSuccess = (payload) => ({type: types.DELETE_DRIVERBLCKLST_SUCCESS,  payload})
export const deleteDataError = (payload) => ({type: types.DELETE_DRIVERBLCKLST_ERROR, error: payload})
// create
export const requestCreateData = (payload) => ({type: types.REQUEST_CREATE_DRIVERBLCKLST, payload})
export const createDataSuccess = (payload) => ({type: types.CREATE_DRIVERBLCKLST_SUCCESS, payload})
export const createDataError = (payload) => ({type: types.CREATE_DRIVERBLCKLST_ERROR, error: payload}) 
// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_DRIVERBLCKLST, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_DRIVERBLCKLST_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_DRIVERBLCKLST_ERROR, error: payload})
// показ окон
export const showWindowAdd = (payload) => ({type: types.SHOW_WINDOW_ADD_DRIVERBLCKLST, payload})  
export const showWindowUpdate = (payload) => ({type: types.SHOW_WINDOW_UPDATE_DRIVERBLCKLST, payload}) 
// приводим все в начальное состояние при закрытии окна
export const clearCreateFields = () => ({type: types.CLEAR_CREATE_FIELDS_DRIVERBLCKLST}) 
export const clearUpdateFields = () => ({type: types.CLEAR_UPDATE_FIELDS_DRIVERBLCKLST}) 
export const clearDeleteFields = () => ({type: types.CLEAR_DELETE_FIELDS_DRIVERBLCKLST})   

// --- Windows request   
//  получаем аудит
export const requestGetAudit = (payload) => ({type: types.REQUEST_GET_AUDIT_FOR_DRIVERBLCKLST, payload})  
export const getGetAuditSuccess = (payload) => ({type: types.SUCCESS_GET_AUDIT_FOR_DRIVERBLCKLST, payload}) 
 
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/auth/auth';
import * as types from './types' 
import * as actions from './actions' 
import history from '../../../history';

import AxiosDefault from '../../../core/utils/axios/AxiosDefault';

 
export default [     
	takeLatest(types.REQUEST_LOGIN, login),   
	takeLatest(types.REQUEST_GET_COMPANYBALANCE_LOGIN, requestGetCompanyBalance),   
]
  
// 2
function* login(action) {
	try {   
		const data = yield call(API.login, action.payload);   
		yield put(actions.successLogin(data))
		
		console.log('Сервер: ', data); 
		AxiosDefault.setAuthAndGetInitialData(data.token) // устанавливаем заголовки в аксиос
		localStorage.setItem('user', data.token); 
		history.push(process.env.REACT_APP_START_PATH)
		
	} catch (error) {  
		console.error('(login error) ', error); 
		yield put(actions.errorLogin(error))
	}
} 

//   
function* requestGetCompanyBalance(action) {
	try {   
		const data = yield call(API.getCompanyBalance, action.payload);   
		yield put(actions.successGetCompanyBalance(data)) 
		
	} catch (error) {   
		yield put(actions.successGetCompanyBalance(null))
	}
} 
// filter
export const REQUEST_CHANGE_ROLES_STATUS = 'REQUEST_CHANGE_ROLES_STATUS'
export const REQUEST_CHANGE_ROLES_SEARCH = 'REQUEST_CHANGE_ROLES_SEARCH'
export const CHANGE_RIGHT_FILTER_ROLES = 'CHANGE_RIGHT_FILTER_ROLES' 
export const USE_FILTER_SUCCESS = 'USE_FILTER_SUCCESS' 
export const CHANGE_ANY_FILTER_ROLES = 'CHANGE_ANY_FILTER_ROLES' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_ROLES = 'REQUEST_GET_ROLES'
export const GET_DATA_ROLES_SUCCESS = 'GET_DATA_ROLES_SUCCESS' 
export const GET_DATA_ROLES_ERROR = 'GET_DATA_ROLES_ERROR' 
// delete 
export const REQUEST_DELETE_ROLES = 'REQUEST_DELETE_ROLES'
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS' 
export const DELETE_ROLES_ERROR = 'DELETE_ROLES_ERROR' 
// update 
export const REQUEST_UPDATE_ROLES = 'REQUEST_UPDATE_ROLES'
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS' 
export const UPDATE_ROLES_ERROR = 'UPDATE_ROLES_ERROR' 
// create 
export const REQUEST_CREATE_ROLES = 'REQUEST_CREATE_ROLES'
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS' 
export const CREATE_ROLES_ERROR = 'CREATE_ROLES_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_ROLES = 'SHOW_WINDOW_ADD_ROLES' 
export const SHOW_WINDOW_UPDATE_ROLES = 'SHOW_WINDOW_UPDATE_ROLES'  
// get cars in window
export const REQUEST_GET_CARS_FOR_ROLES = 'REQUEST_GET_CARS_FOR_ROLES' 
export const GET_CARS_FOR_ROLES_SUCCESS = 'GET_CARS_FOR_ROLES_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_ROLES = 'REQUEST_GET_ONECAR_FOR_ROLES' 
export const GET_ONECAR_FOR_ROLES_SUCCESS = 'GET_ONECAR_FOR_ROLES_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_ROLES = 'CLEAR_CREATE_FIELDS_ROLES' 
export const CLEAR_UPDATE_FIELDS_ROLES = 'CLEAR_UPDATE_FIELDS_ROLES'  
export const CLEAR_DELETE_FIELDS_ROLES = 'CLEAR_DELETE_FIELDS_ROLES'    


// --- Windows request 
// get company
export const REQUEST_GET_COMPANIES_FOR_ROLES = 'REQUEST_GET_COMPANIES_FOR_ROLES'  
export const SUCCESS_GET_COMPANIES_FOR_ROLES = 'SUCCESS_GET_COMPANIES_FOR_ROLES'  
// get roles
export const REQUEST_GET_ROLES_FOR_ROLES = 'REQUEST_GET_ROLES_FOR_ROLES'  
export const SUCCESS_GET_ROLES_FOR_ROLES = 'SUCCESS_GET_ROLES_FOR_ROLES'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_ROLES = 'REQUEST_GET_AUDIT_FOR_ROLES'  
export const SUCCESS_GET_AUDIT_FOR_ROLES = 'SUCCESS_GET_AUDIT_FOR_ROLES'  

 
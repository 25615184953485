import { createAction } from 'redux-act';
 
export const showSidebar = createAction('show/hide left sedibar'); 
export const toggleUserProfile = createAction('toggleUserProfile');
export const loadUserProfileData = createAction('loadUserProfileData');
export const successUserProfileData = createAction('successUserProfileData'); 

export const changeUserPassword = createAction('changeUserPassword'); 
export const resultChangeUserPassword = createAction('resultChangeUserPassword'); 
export const setResultChangeUserPassword = createAction('setResultChangeUserPassword'); 


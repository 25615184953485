import axios from 'axios'
import {catchError} from '../apiTools';

export default {  

	getData: (filter) => axios.post(`/pilot/debt`, filter).then(r => r.data),
	createData: (data) => axios.post(`/pilot/debt/add/order`, data).then(r => r.data).catch(r => catchError(r)),  
	deleteData: (data) => axios.post(`/pilot/debt/del/${data.id}`).then(r => r.data), 

	// small part for pilot "Canceled orders statistics"
	getDataCanceledStatistics: (data) => axios.post('/pilot/cancelReasons', data).then(r => r.data),

}
	 
import axios from 'axios'
import {catchError} from '../apiTools';
 
// const getData = [
// 	{id: 0, name: 'Оператор', pages: {
// 		'orders': 14,
// 		'clients': 7,
// 		'corpClients': 7,
// 		'clientsBlackList': 7,
// 		'map': 10,

// 		'mailList': 0,
// 		'dialogs': 7,

// 		'drivers': 7,
// 		'photoInspection': 7,
// 		'driversControl': 7,
// 		'driversNotice': 7,
// 		'driversBlackList': 7,
// 		'columns': 7,
// 		'cars': 7,
// 		'carsControl': 7,
// 		'carsService': 7,
// 		'carsInsurance': 7,
// 		'classTaxi': 7,
// 		//финансы
// 		'transactions': 7,
// 		'balanceArrival': 7,
// 		'balanceDebet': 7,
// 		'balanceDebetFuel': 7,
// 		'balanceDebetCarwash': 7,
// 		//отчеты 
// 		'reportAgent': 7,
// 		'reportMoney': 7,
// 		'reportDrivers': 7,
// 		'reportCars': 7,
// 		// админ
// 		creatingCompany: 7,
// 		creatingUsers: 15,
// 		usersRoles: 14,
// 		tariffEdit: 14,
// 		services: 0,
// 		settingApps: 14,
// 	}},
// ]

export default {  
	getData: (filter) => axios.post(`/roles/list`, filter).then(r => r.data),  
	createData: (data) => axios.post(`/roles/add`, data).then(r => catchError(r)), 
	updateData: (data) => axios.post(`/roles/upd/${data.id}`, data.payload).then(r => catchError(r)), 
	deleteData: (data) => axios.post(`/roles/del/${data.id}`).then(r => r.data),  
}
  
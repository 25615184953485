// filter

export const CHANGE_FILTER_MESSAGE = 'CHANGE_FILTER_MESSAGE' 
export const USE_FILTER_SUCCESS_MESSAGE = 'USE_FILTER_SUCCESS_MESSAGE' 
// get
export const REQUEST_GET_MESSAGES = 'REQUEST_GET_MESSAGES'
export const GET_DATA_MESSAGES_SUCCESS = 'GET_DATA_MESSAGES_SUCCESS' 
export const GET_DATA_MESSAGES_ERROR = 'GET_DATA_MESSAGES_ERROR' 
// delete 
export const REQUEST_DELETE_MESSAGE = 'REQUEST_DELETE_MESSAGE'
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS' 
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR' 
// update 
export const REQUEST_UPDATE_MESSAGE = 'REQUEST_UPDATE_MESSAGE'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS' 
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR' 
// create 
export const REQUEST_CREATE_MESSAGE = 'REQUEST_CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_DRIVER_SUCCESS' 
export const CREATE_MESSAGE_ERROR = 'CREATE_DRIVER_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_MESSAGE = 'SHOW_WINDOW_ADD_MESSAGE' 
export const SHOW_WINDOW_UPDATE_MESSAGE = 'SHOW_WINDOW_UPDATE_MESSAGE'  

export const CHANGE_PAGIN_MESSAGES = 'CHANGE_PAGIN_MESSAGES'  

export const REQUEST_GET_TYPES = 'REQUEST_GET_TYPES'
export const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS'

export const CLEAR_PROGRESS_DATA = 'CLEAR_PROGRESS_DATA'
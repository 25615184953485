import * as types from './types'; 

// update
export const requestUpdateData = (payload) => ({type: types.REQUEST_UPDATE_SETTINGAPP, payload})
export const updateDataSuccess = (payload) => ({type: types.UPDATE_SETTINGAPP_SUCCESS,  payload})
export const updateDataError = (payload) => ({type: types.UPDATE_SETTINGAPP_ERROR, error: payload})
export const clearUpdateFields = (payload) => ({type: types.CLEAR_UPDATE_FIELDS_SETTINGAPP, payload})  

// --- Windows request      
//  получаем всю информацию об 1 компании
export const requestGetOneCompany = (payload) => ({type: types.REQUEST_GET_COMPANY_FOR_SETTINGAPP, payload})  
export const getOneCompanySuccess = (payload) => ({type: types.SUCCESS_GET_COMPANY_FOR_SETTINGAPP, payload}) 
export const loadShowPrice = (payload) => ({type: types.LOAD_SHOW_PRICE, payload})
export const successLoadShowPrice = (payload) => ({type: types.SUCCESS_LOAD_SHOW_PRICE, payload})
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/static/dictionaries';
import * as types from './types' 
import * as actions from './actions' 
 
export default [  
	takeLatest(types.REQUEST_GET_CARS_BRANDS, getBrands),  
	takeLatest(types.REQUEST_GET_CARS_MODELS, getModels),   
	takeLatest(types.REQUEST_GET_TARIFFS, getTariffs),   
	takeLatest(types.REQUEST_GET_REGIONS_DICT, getRegions),   
	takeLatest(types.REQUEST_GET_REGIONS_DICT, getRegions),   
	takeLatest(types.REQUEST_GET_AUTOCLASSID_STATIC, requestGetAutoClassId),   
	takeLatest(types.REQUEST_GET_ALLDICT_STATIC, requestGetAllDict),   
]   
 
// 1
function* getBrands(action) {
	try {   
		const data = yield call(API.getCarsBrands, null);  
		yield put(actions.getBrandsSucces(data))
		
	} catch (error) {  
		console.error('(getBrands) ', error); 
		yield put(actions.getBrandsSucces([]))
	}
}

// 2
function* getModels(action) {
	try {   
		const data = yield call(API.getCarsModels, action.payload);   
		yield put(actions.getModelsSucces(data))
		
	} catch (error) {  
		console.error('(getModels) ', error); 
		yield put(actions.getModelsSucces([]))
	}
}

// 2
function* getTariffs(action) {
	try {    
		const data = yield call(API.getTariffs, action.payload);   
		yield put(actions.getTariffsSuccess(data))
		
	} catch (error) {  
		console.error('(getTariffsSuccess) ', error); 
		yield put(actions.getTariffsSuccess([]))
	}
}

// 
function* getRegions(action) {
	try {   
		const data = yield call(API.getRegions);    
		yield put(actions.successGetRegions(data))
		
	} catch (error) {   
		yield put(actions.successGetRegions([]))
	}
}

// 
function* requestGetAllDict(action) {
	try {   
		const data = yield call(API.getAllDict);   
		yield put(actions.getAllDictSuccess(data))
		
	} catch (error) {   
		yield put(actions.getAllDictSuccess([]))
	}
}

// 
function* requestGetAutoClassId(action) {
	try {   
		const data = yield call(API.getAutoClassId);   
		yield put(actions.getAutoClassIdSuccess(data))
		
	} catch (error) {   
		yield put(actions.getAutoClassIdSuccess([]))
	}
}
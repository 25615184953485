import axios from 'axios'
import {catchError} from '../apiTools';

export default {  
	getData: (filter) => axios.post(`/classTaxi/list`, filter).then(r => r.data),
	createData: (data) => axios.post(`/classTaxi/add`, data).then(r => r.data).catch(r => catchError(r)),  
	updateData: (data) => axios.post(`/classTaxi/upd/${data.id}`, data.payload).then(r => r.data),
	deleteData: (data) => axios.post(`/classTaxi/del/${data.id}`).then(r => r.data), 
	createModalData: (data) => axios.post('/classTaxi/createBrand', data).then(r => r.data).catch(r=> catchError(r)),
	getOneCar: (data) => axios.post(`classTaxi/get/${data.id}`).then(r=>r.data)
}
	 
import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/controlRoom/clients';  
import * as types from './types' 
import * as actions from './actions' 
 
export default [ 
	takeLatest(types.REQUEST_GET_CLIENTS, requestGetData),   

  takeLatest(types.REQUEST_CHANGE_CLIENTS_STATUS, useFilter),   
  takeLatest(types.REQUEST_CHANGE_CLIENTS_SEARCH, useFilter),   
	takeLatest(types.CHANGE_RIGHT_FILTER_CLIENTS, useFilter),  
	// in widnows request   
	takeLatest(types.REQUEST_GET_AUDIT_FOR_CLIENTS, getAudit),  
]

// 1
function* requestGetData(action) {
	try {   
		const data = yield call(API.getData, action.payload);    
		yield put(actions.getDataPageSuccess(data))
		
	} catch (error) {  
		console.error('(getDataPageError CLIENTS)', error);
		yield put(actions.getDataPageError(error))
	}
}

// 2
function* useFilter(action) {
	try {   
		const data = yield call(API.getData, action.filterData);  
		yield put(actions.useFilterSuccess(data))
		
	} catch (error) {  
		yield put(actions.useFilterSuccess([]))
	}
}
   
  
//  
function* getAudit(action) {
	try {   
		const data = yield call(API.getAudit, action.payload);   
		yield put(actions.getGetAuditSuccess(data))   
		
	} catch (error) {  
		console.error('(getAudit FOR CLIENTS) ', error); 
		yield put(actions.getGetAuditSuccess([]))
	}
}
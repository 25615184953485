// filter
export const REQUEST_CHANGE_CREATECOMP_STATUS = 'REQUEST_CHANGE_CREATECOMP_STATUS'
export const REQUEST_CHANGE_CREATECOMP_SEARCH = 'REQUEST_CHANGE_CREATECOMP_SEARCH'
export const CHANGE_RIGHT_FILTER_CREATECOMP = 'CHANGE_RIGHT_FILTER_CREATECOMP' 
export const USE_FILTER_SUCCESS = 'USE_FILTER_SUCCESS' 
export const CHANGE_ANY_FILTER_CREATECOMP = 'CHANGE_ANY_FILTER_CREATECOMP' // изменяем любой другой доп фильтр (не сайд-эффект)
//send correct data
export const SEND_CORRECT_DATA = "SEND_CORRECT_DATA";
export const SEND_CORRECT_DATA_SUCCESS = "SEND_CORRECT_DATA_SUCCESS";
export const SEND_CORRECT_DATA_ERROR = "SEND_CORRECT_DATA_ERROR";
//SMS
export const REQUEST_SMS_SERVICES_DATA = "REQUEST_SMS_SERVICES_DATA";
export const SMS_SERVICES_DATA_SUCCESS = "SMS_SERVICES_DATA_SUCCESS";
export const SMS_SERVICES_DATA_ERROR = "SMS_SERVICES_DATA_ERROR";
//send single company id
export const SEND_COMPANY_ID = "SEND_COMPANY_ID";
export const SEND_COMPANY_ID_SUCCESS = "SEND_COMPANY_ID_SUCCESS";
export const SEND_COMPANY_ID_ERROR = "SEND_COMPANY_ID_ERROR";
//get subcompanies
export const REQUEST_GET_SUBCOMPANIES = 'REQUEST_GET_SUBCOMPANIES'
export const GET_SUBCOMPANIES_DATA_SUCCESS = 'GET_SUBCOMPANIES_DATA_SUCCESS' 
export const GET_SUBCOMPANIES_DATA_ERROR = 'GET_SUBCOMPANIES_DATA_ERROR'
// get
export const REQUEST_GET_CREATECOMP = 'REQUEST_GET_CREATECOMP'
export const GET_DATA_CREATECOMP_SUCCESS = 'GET_DATA_CREATECOMP_SUCCESS' 
export const GET_DATA_CREATECOMP_ERROR = 'GET_DATA_CREATECOMP_ERROR' 
// delete 
export const REQUEST_DELETE_CREATECOMP = 'REQUEST_DELETE_CREATECOMP'
export const DELETE_CREATECOMP_SUCCESS = 'DELETE_CREATECOMP_SUCCESS' 
export const DELETE_CREATECOMP_ERROR = 'DELETE_CREATECOMP_ERROR' 
// update 
export const REQUEST_UPDATE_CREATECOMP = 'REQUEST_UPDATE_CREATECOMP'
export const UPDATE_CREATECOMP_SUCCESS = 'UPDATE_CREATECOMP_SUCCESS' 
export const UPDATE_CREATECOMP_ERROR = 'UPDATE_CREATECOMP_ERROR' 
// create 
export const REQUEST_CREATE_CREATECOMP = 'REQUEST_CREATE_CREATECOMP'
export const CREATE_CREATECOMP_SUCCESS = 'CREATE_CREATECOMP_SUCCESS' 
export const CREATE_CREATECOMP_ERROR = 'CREATE_CREATECOMP_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_CREATECOMP = 'SHOW_WINDOW_ADD_CREATECOMP' 
export const SHOW_WINDOW_UPDATE_CREATECOMP = 'SHOW_WINDOW_UPDATE_CREATECOMP'  
// get cars in window
export const REQUEST_GET_CARS_FOR_CREATECOMP = 'REQUEST_GET_CARS_FOR_CREATECOMP' 
export const GET_CARS_FOR_CREATECOMP_SUCCESS = 'GET_CARS_FOR_CREATECOMP_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_CREATECOMP = 'REQUEST_GET_ONECAR_FOR_CREATECOMP' 
export const GET_ONECAR_FOR_CREATECOMP_SUCCESS = 'GET_ONECAR_FOR_CREATECOMP_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_CREATECOMP = 'CLEAR_CREATE_FIELDS_CREATECOMP' 
export const CLEAR_UPDATE_FIELDS_CREATECOMP = 'CLEAR_UPDATE_FIELDS_CREATECOMP'  
export const CLEAR_DELETE_FIELDS_CREATECOMP = 'CLEAR_DELETE_FIELDS_CREATECOMP'
export const CLEAR_CORRECT_DATA_FIELDS = "CLEAR_CORRECT_DATA_FIELDS";    
// отправлять/получать заказы чекбоксами
export const REQUEST_CHANGE_COMPANYORDERS_CREATECOMP = 'REQUEST_CHANGE_COMPANYORDERS_CREATECOMP' 
export const SUCCESS_CHANGE_COMPANYORDERS_CREATECOMP = 'SUCCESS_CHANGE_COMPANYORDERS_CREATECOMP'  
export const ERROR_CHANGE_COMPANYORDERS_CREATECOMP = 'ERROR_CHANGE_COMPANYORDERS_CREATECOMP'    
export const CLEAR_FIELDS_CHANGE_COMPANYORDERS_CREATECOMP = 'CLEAR_FIELDS_CHANGE_COMPANYORDERS_CREATECOMP'    


// --- Windows request 
// показ окон для создание апдейта пассажиров
export const SHOW_WINDOW_PASSENGERS_ADD_CREATECOMP = 'SHOW_WINDOW_PASSENGERS_ADD_CREATECOMP'  
export const SHOW_WINDOW_PASSENGERS_UPDATE_CREATECOMP = 'SHOW_WINDOW_PASSENGERS_UPDATE_CREATECOMP'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_CREATECOMP = 'REQUEST_GET_AUDIT_FOR_CREATECOMP'  
export const SUCCESS_GET_AUDIT_FOR_CREATECOMP = 'SUCCESS_GET_AUDIT_FOR_CREATECOMP'   
//  получаем сервисы для таба
export const REQUEST_GET_SERVICES_FOR_CREATECOMP = 'REQUEST_GET_SERVICES_FOR_CREATECOMP'
export const SUCCESS_GET_SERVICES_FOR_CREATECOMP = 'SUCCESS_GET_SERVICES_FOR_CREATECOMP' 
//  получаем всю информацию об 1 компании
export const REQUEST_GET_COMPANY_FOR_CREATECOMP = 'REQUEST_GET_COMPANY_FOR_CREATECOMP' 
export const SUCCESS_GET_COMPANY_FOR_CREATECOMP = 'SUCCESS_GET_COMPANY_FOR_CREATECOMP'  

export const REQUEST_CHANGE_CREATECOMP_INWORK = 'REQUEST_CHANGE_CREATECOMP_INWORK'  

export const REQUEST_RECOVER_COMPANY = 'REQUEST_RECOVER_COMPANY'  
export const SUCCESS_RECOVER_COMPANY = 'SUCCESS_RECOVER_COMPANY'  
 export const ERROR_RECOVER_COMPANY = 'ERROR_RECOVER_COMPANY'  
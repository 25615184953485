import axios from 'axios'
import {catchError} from '../apiTools';

export default { 
	getData: (payload) => axios.post(payload.targetUrl || '/driver/list', payload.filter).then(r => r.data),
	createData: (data) => axios.post(`/driver/add`, data).then(r => r.data).catch(r => catchError(r)),
	updateData: (payload) => axios.post(`/driver/upd/${payload.id}`, payload.request).then(r => r.data).catch(r => catchError(r)),
	deleteData: (payload) => axios.post(`/driver/del/${payload}`).then(r => r.data),
	getCars: (filter) => axios.post(`/car/list`, filter).then(r => r.data),
	getOneCar: (id) => axios.post(`/car/get/${id}`).then(r => r.data),
	getOneDriver: (id) => axios.post(`/driver/get/${id}`).then(r => r.data),
	// --- Window requests
	getAudit: (payload) => axios.post(`/driver/audit/${payload.id}`, payload).then(r => r.data),
	getAuditParams: (payload) => axios.post(`/dic/driver/description`, payload).then(r => r.data),
	// getDriverOrders: (payload) => axios.post(`/driver/orders/5501`, payload).then(r => r.data),
  getDriverOrders: (payload) => axios.post(`/driver/orders/${payload.id}`, payload).then(r => r.data),
  getDriverOrdersParams: (payload) => axios.post(`/driver/orders/${payload.id}`, payload.filter).then(r => r.data),
	getLogEvents: (payload) => axios.post(`/driver/events/${payload.id}`, payload).then(r => r.data),
	getFinances: (payload) => axios.post(`/driver/finances/${payload.id}`, payload).then(r => r.data),
	createReplenish: (payload) => axios.post(`/driver/replenish`, payload).then(r => r.data).catch(r => catchError(r)),
	createWithdraw: (payload) => axios.post(`/driver/withdraw`, payload).then(r => r.data).catch(r => catchError(r)),

  getDriverPhotos: (payload) => axios.post(`/photoInspection/get/${payload.id}`).catch(() => getDriverPhotos),
  
  getDriverInfo: (id) => axios.post(`driver/getCore/${id}`).then(r => r.data)
} 

 
const getDriverPhotos = [
  {
    label: 'Перед',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Левый бок',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Зад',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'Правый бок',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Открытый багажник',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];
import { createAction } from 'redux-act';

/** 
 * createAction - создает уникальный ID. Если нужно использовать заготовленный текст, то юзай:
 * createAction('GET_ALL_TOPS')  =>преобразуется=>  {type: "GET_ALL_TOPS", payload}
 */
// filter 
export const requestChangeStatus = createAction('[partnersPage] requestChangeStatus')
export const requestChangeParamSearch = createAction('[partnersPage] requestChangeParamSearch')
export const requestChangeRightFilter = createAction('[partnersPage] requestChangeRightFilter')
export const changePagination = createAction('[partnersPage] changePagination')
// все реквесты на фильтры идут в этот обработчик
export const useFilterSuccess =  createAction('[partnersPage] useFilterSuccess')
// get
export const requestGetDataPage = createAction('[partnersPage] requestGetDataPage')
export const getDataPageSuccess = createAction('[partnersPage] getDataPageSuccess')
export const getDataPageError = createAction('[partnersPage] getDataPageError') 
// update
export const requestUpdateData = createAction('[partnersPage] requestUpdateData') 
export const updateDataSuccess = createAction('[partnersPage] updateDataSuccess') 
export const updateDataError = createAction('[partnersPage] updateDataError')  
//clear
export const clearUpdateFields = createAction('[partnersPage] clearUpdateFields')  
import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../../api/dictionaries/unIdentifiedObjects";
import { pushBrandsSuccess } from "../../../../store/static/dictionaries/actions";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  takeLatest(actions.requestCreateData, createData),
  takeLatest(actions.requestDeleteData, deleteData),
  takeLatest(actions.requestUpdateData, updateData),
  takeLatest(actions.requestChangeStatus, useFilter),
  takeLatest(actions.requestChangeParamSearch, useFilterObjects),
  takeLatest(actions.requestChangeRightFilter, useFilter),
  takeLatest(actions.requestGetDataObjects, requestGetDataObjects),
  takeLatest(actions.sendObjectsTableData, sendTableData)
];

function* sendTableData(action){
  try { 
    const data = yield call(API.sendTableData, action.payload);
    yield put(actions.sendObjectsTableDataSuccess(data));
  } catch(error){
    yield put(actions.sendObjectsTableDataError(error.errorMessage));
  }  
}

// 1
function* requestGetData(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    console.error("(getDataPageError unIdentifiedObjects)", error);
    yield put(actions.getDataPageError(error));
  }
}

function* requestGetDataObjects(action) {
  try {
    const data = yield call(API.getDataForTable, action.payload);
    yield put(actions.getDataObjectsSuccess(data));
  } catch (error) {
    console.error("(getDataObjectsError unIdentifiedObjects)", error);
    yield put(actions.getDataObjectsError(error));
  }
}

//
function* useFilter(action) {
  try {
    const data = yield call(API.getData, action.payload.filterData);
    yield put(actions.useFilterSuccess(data));
  } catch (error) {
    yield put(actions.useFilterSuccess([]));
  }
}

function* useFilterObjects(action){
  try{
    const data = yield call(API.getDataForTable, action.payload);
    yield put(actions.useFilterSuccess(data));
  } catch(error){
    yield put(actions.useFilterSuccess([]));
  }
}

//
function* createData(action) {
  try {
    const data = yield call(API.createData, action.payload);
    yield put(actions.createDataSuccess(data));
    yield put(pushBrandsSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(actions.createDataError(error));
  }
}

//
function* deleteData(action) {
  try {
    const data = yield call(API.deleteData, action.payload);
    yield put(actions.deleteDataSuccess(data));
  } catch (error) {
    console.error("(deleteData for unIdentifiedObjects) ", error);
    yield put(actions.deleteDataError(error));
  }
}

//
function* updateData(action) {
  try {
    const data = yield call(API.updateData, action.payload);
    yield put(actions.updateDataSuccess(data));
  } catch (error) {
    console.error("(updateData for unIdentifiedObjects) ", error);
    yield put(actions.updateDataError(error));
  }
}

import * as types from './types';

const initialState = { 
	update: { // для работы при изменении 
		loading: false,  
		error: false,   
		success: false,   
	},
	sysSettings: {
		smsServices: [],
		data: {},
		loading: false,
	} 
}


export default (state=initialState, action) => {
	  
	switch (action.type) {
	 
			// --- Async update data ->>
		case types.REQUEST_UPDATE_SYSTEMSETTINGS: 
			return {...state, update: {...state.update, loading: true}}  
		case types.UPDATE_SYSTEMSETTINGS_SUCCESS:  
			return {...state, update: {...state.update, loading: false, success: true, error: false}, sysSettings: {...state.sysSettings, 
				data: action.payload}}  
		case types.UPDATE_SYSTEMSETTINGS_ERROR: 
			return {...state, update: {...state.update, loading: false, error: action.error, success: false}}  
		case types.CLEAR_UPDATE_FIELDS_SYSTEMSETTINGS: 
			return {...state, update: {...state.update, loading: false, error: false, success: false}} // initial 
	
		case types.SUCCESS_GET_SYSTEMSETTINGS:
			return {...state, sysSettings: {...state.sysSettings, data: action.payload}}

		case types.LOAD_SMS_SERVICES:
			return {...state, sysSettings: {...state.sysSettings, smsServices: action.payload}}

		// --- logout from systeam
		case 'LOGOUT_FROM_SYSTEM':   
			return initialState  
				
		default: 
			return state

	}
}
 
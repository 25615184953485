import axios from 'axios'

export default { 
	getCars: (filter='') => axios.post(`/car/list`,  filter).then(r => r.data), 
	createCar: (data) => axios.post(`/car/add`,  data).then(r => r.data),
	updateCar: (payload) => axios.post(`/car/upd/${payload.id}`, payload.request).then(r => r.data), // payload = {request, id}
	deleteCar: (payload) => axios.post(`/car/del/${payload}`).then(r => r.data), 

	getDriversForCars: (data) => axios.post(`/car/driversForCar`,  data).then(r => r.data),
	getAttachedDriversForCars: (data) => 	axios.post(`/car/attachedDrivers`, data).then(r => r.data),

	getOneCar: (id) => 	axios.post(`/car/get/${id}`).then(r => r.data),
}
  
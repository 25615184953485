import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "../../../../api/reports/reportPhotoinspection";
import * as actions from "./actions";

export default [
  takeLatest(actions.requestGetDataPage, requestGetData),
  // takeLatest(actions.changeAnyFilter, changeAnyFilter),
  takeLatest(actions.requestGetCompaniesData, requestCompanies),
  takeLatest(actions.requestGetUsers, requestGetUsers)
];


function* requestGetData(action) {
  try {
    const data = yield call(API.getData, action.payload);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    console.error("(getDataPageError reportPhotoinspection)", error);
    yield put(actions.getDataPageError(error));
  }
}

function* changeAnyFilter(action) {
  try {
    let state = yield select();
    const data = yield call(API.getData, state.reports.reportPhotoinspection.filter);
    yield put(actions.getDataPageSuccess(data));
  } catch (error) {
    yield put(actions.getDataPageError(error));
  }
}

function* requestCompanies(action){
  try{
    const data = yield call(API.getCompanies, action.payload);
    yield put(actions.getCompaniesDataSuccess(data));
  }catch(error){
    console.log("getCompanies ERROR, reportGiveOrder", error);
    yield put(actions.getDataPageError(error));
  }
}

function* requestGetUsers(action){
  try{
    const data = yield call(API.getUsers, action.payload);
    data.unshift({id: null, fio: "Все"})
    yield put(actions.getUsersDataSuccess(data.map(u=>({value: u.id, label: u.fio}))));
  }catch(error){
    console.log("getCompanies ERROR, reportGiveOrder", error);
    yield put(actions.getDataPageError(error));
  }
}




import axios from 'axios'

const getSystemSettings = {
	gpsValidityDuration: 2,  //Длительность валидности GPS координат (в минутах) 
    minutesBeforeRedistributing: 1.00, //Количество минут перед повторным распределением
    ultimateMaximumClientLatency: 20, //Конечное максимальное время ожидания машины клиентом (в минутах)
    driverSearchRadius: 10000.00, //Радиус поиска водителей (в метрах)
    secondsBetweenDistrAndFixing: 19, //Количество секунд между началом распределения и закреплением заказа
    thresholdForTransition: 25, //Порог перехода предварительного заказа в текущий (в минутах)
    maxWaitingForConfirmation: 50, //Максимальное время ожидания подтверждения от поставщика (в секундах)
    numDaysForStatistic: 365, //Число дней, за которое хранится статистика о водителях на линии
    smsSendingService: 0, //Сервис отправки SMS enums[Тестовый сервис, AgregatorService]
    urlSendingService: 'http://login.sms-easy.ru/sendsms/send?login=602upup0&pwd=JMmSFCmw&sadr=upup', //URL для отправки SMS
    smtpHost: 'smtp.gmail.com', //Smtp host
    emailAddress: 'testtoeportal@gmail.com', //Email address
    password: '', //Password
    serverPOISUrl: 'https://test.multipassme.ru/app/ois/', //Server POI SUrl
    apiMoisUrl: 'https://test.multipassme.ru/app/ois/',
    OISApiKey: '996BF64C8E1647ECA1AD5AF8332574DB', //OIS Api Key
    POISURL: 'https://test2.sedi.ru:9050/api/Pois', //POIS URL
}

export default {  
	updateSystemSettings: (data) => axios.post(`/settingSystem/upd`, data.payload).then(r => r.data),  
    getSystemSettings: () => axios.post(`/settingSystem/get`).then(r => r.data),
    loadSmsServices: () => axios.post(`/settingSystem/smsServices`).then(r=>r.data)
}
  
// filter
export const REQUEST_CHANGE_ORDERS_STATUS = 'REQUEST_CHANGE_ORDERS_STATUS'
export const REQUEST_CHANGE_ORDERS_SEARCH = 'REQUEST_CHANGE_ORDERS_SEARCH'
export const CHANGE_RIGHT_FILTER_ORDERS = 'CHANGE_RIGHT_FILTER_ORDERS' 
export const USE_FILTER_SUCCESS_ORDERS = 'USE_FILTER_SUCCESS_ORDERS' 
export const CHANGE_ANY_FILTER_ORDERS = 'CHANGE_ANY_FILTER_ORDERS' // изменяем любой другой доп фильтр (не сайд-эффект)
// get
export const REQUEST_GET_ORDERS = 'REQUEST_GET_ORDERS'
export const GET_DATA_ORDERS_SUCCESS = 'GET_DATA_ORDERS_SUCCESS' 
export const GET_DATA_ORDERS_ERROR = 'GET_DATA_ORDERS_ERROR'

//get one order
export const REQUEST_ONE_ORDER = "REQUEST_ONE_ORDER";
export const GET_ONE_ORDER_SUCCESS = "GET_ONE_ORDER_SUCCESS";
export const GET_ONE_ORDER_ERROR = "GET_ONE_ORDER_ERROR";
export const CLEAR_ONE_ORDER = "CLEAR_ONE_ORDER";

//get companies
export const REQUEST_GET_DATA_COMPANIES = "REQUEST_GET_DATA_COMPANIES";
export const GET_DATA_COMPANIES_SUCCESS = "GET_DATA_COMPANIES_SUCCESS";
export const GET_DATA_COMPANIES_ERROR = "GET_DATA_COMPANIES_ERROR";
//send order to the exchanger
export const SEND_ORDER = "SEND_ORDER";
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
export const SEND_ORDER_ERROR = "SEND_ORDER_ERROR";
//send companies data
export const SEND_COMPANIES_DATA = "SEND_COMPANIES_DATA";
export const SEND_COMPANIES_DATA_SUCCESS = "SEND_COMPANIES_DATA_SUCCESS";
export const SEND_COMPANIES_DATA_ERROR = "SEND_COMPANIES_DATA_ERROR";
export const CLEAR_COMPANIES_DATA_FIELDS = "CLEAR_COMPANIES_DATA_FIELDS";
// delete 
export const REQUEST_DELETE_ORDERS = 'REQUEST_DELETE_ORDERS'
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS' 
export const DELETE_ORDERS_ERROR = 'DELETE_ORDERS_ERROR' 
// update 
export const REQUEST_UPDATE_ORDERS = 'REQUEST_UPDATE_ORDERS'
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS' 
export const UPDATE_ORDERS_ERROR = 'UPDATE_ORDERS_ERROR' 
// create 
export const REQUEST_CREATE_ORDERS = 'REQUEST_CREATE_ORDERS'
export const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS' 
export const CREATE_ORDERS_ERROR = 'CREATE_ORDERS_ERROR' 
// показ окон
export const SHOW_WINDOW_ADD_ORDERS = 'SHOW_WINDOW_ADD_ORDERS' 
export const SHOW_WINDOW_UPDATE_ORDERS = 'SHOW_WINDOW_UPDATE_ORDERS'  
// get cars in window
export const REQUEST_GET_CARS_FOR_ORDERS = 'REQUEST_GET_CARS_FOR_ORDERS' 
export const GET_CARS_FOR_ORDERS_SUCCESS = 'GET_CARS_FOR_ORDERS_SUCCESS'
// получаем 1 авто
export const REQUEST_GET_ONECAR_FOR_ORDERS = 'REQUEST_GET_ONECAR_FOR_ORDERS' 
export const GET_ONECAR_FOR_ORDERS_SUCCESS = 'GET_ONECAR_FOR_ORDERS_SUCCESS'     
// очищение реквестов
export const CLEAR_CREATE_FIELDS_ORDERS = 'CLEAR_CREATE_FIELDS_ORDERS' 
export const CLEAR_UPDATE_FIELDS_ORDERS = 'CLEAR_UPDATE_FIELDS_ORDERS'  
export const CLEAR_DELETE_FIELDS_ORDERS = 'CLEAR_DELETE_FIELDS_ORDERS' 
//  получаем 1 водителя 
export const REQUEST_GET_DRIVER_FOR_ORDERS = 'REQUEST_GET_DRIVER_FOR_ORDERS'  
export const SUCCESS_GET_DRIVER_FOR_ORDERS = 'SUCCESS_GET_DRIVER_FOR_ORDERS'  


// --- Windows request
//  получаем контрагента counterpartyName
export const REQUEST_GET_COUNTERPARTY_FOR_ORDERS = 'REQUEST_GET_COUNTERPARTY_FOR_ORDERS'  
export const SUCCESS_GET_COUNTERPARTY_FOR_ORDERS = 'SUCCESS_GET_COUNTERPARTY_FOR_ORDERS'  
//  получаем персон у контрагента 
export const REQUEST_GET_PERSON_FOR_ORDERS = 'REQUEST_GET_PERSON_FOR_ORDERS'  
export const SUCCESS_GET_PERSON_FOR_ORDERS = 'SUCCESS_GET_PERSON_FOR_ORDERS'  
//  получаем пассажира по телефону passenger
export const REQUEST_GET_PASSENGER_FOR_ORDERS = 'REQUEST_GET_PASSENGER_FOR_ORDERS'  
export const SUCCESS_GET_PASSENGER_FOR_ORDERS = 'SUCCESS_GET_PASSENGER_FOR_ORDERS'  
//  получаем кординаты и названиея requestGetTextAndCoordinates
export const REQUEST_GET_COORDINATES_FOR_ORDERS = 'REQUEST_GET_COORDINATES_FOR_ORDERS'  
export const SUCCESS_GET_COORDINATES_FOR_ORDERS = 'SUCCESS_GET_COORDINATES_FOR_ORDERS'  
//  получаем кординаты и названиея requestGetTextAndCoordinates
export const REQUEST_GET_DRIVERS_FOR_ORDERS = 'REQUEST_GET_DRIVERS_FOR_ORDERS'  
export const SUCCESS_GET_DRIVERS_FOR_ORDERS = 'SUCCESS_GET_DRIVERS_FOR_ORDERS'  
//  получаем сумму по парамам
export const REQUEST_GET_SUM_FOR_ORDERS = 'REQUEST_GET_SUM_FOR_ORDERS'  
export const SUCCESS_GET_SUM_FOR_ORDERS = 'SUCCESS_GET_SUM_FOR_ORDERS'  
//  поулчаем аудит
export const REQUEST_GET_AUDIT_FOR_ORDERS = 'REQUEST_GET_AUDIT_FOR_ORDERS'  
export const SUCCESS_GET_AUDIT_FOR_ORDERS = 'SUCCESS_GET_AUDIT_FOR_ORDERS'  
// получаем маршрут для трэка
export const REQUEST_GET_MAPTRACK_FOR_ORDERS = 'REQUEST_GET_MAPTRACK_FOR_ORDERS' 
export const SUCCESS_GET_MAPTRACK_FOR_ORDERS = 'SUCCESS_GET_MAPTRACK_FOR_ORDERS'  
export const CLEAR_FIELDS_MAPTRACK_FOR_ORDERS = 'CLEAR_FIELDS_MAPTRACK_FOR_ORDERS'  
// получаем таксометр для вкладки
export const REQUEST_GET_TAXIMETER_FOR_ORDERS = 'REQUEST_GET_TAXIMETER_FOR_ORDERS' 
export const SUCCESS_GET_TAXIMETER_FOR_ORDERS = 'SUCCESS_GET_TAXIMETER_FOR_ORDERS'   
export const CLEAR_TAXIMETER = 'CLEAR_TAXIMETER'

// меняем фильтр локально
export const CHANGE_PAGIN_ORDERS = 'CHANGE_PAGIN_ORDERS'   

export const REQUEST_GET_DRIVERTRACK_FOR_ORDERS = 'REQUEST_GET_DRIVERTRACK_FOR_ORDERS' 
export const SUCCESS_GET_DRIVERTRACK_FOR_ORDERS = 'SUCCESS_GET_DRIVERTRACK_FOR_ORDERS'  